<template>

    <div style="height: 0; width: 0; position: absolute; visibility: hidden">
      <!-- inject:svg --><svg xmlns="http://www.w3.org/2000/svg"><symbol id="arrow-left" viewBox="0 0 6 12"><path d="M1.931 6L6 1.959 5.034 1 0 6l5.034 5L6 10.041z" fill="currentColor" stroke="currentColor" fill-rule="evenodd"/></symbol><symbol id="arrow-square" viewBox="0 0 26 26"><g fill="none" fill-rule="evenodd"><path stroke="#000" d="M1 1h24v24H1z"/><path d="M19.768 14.362a1 1 0 00-1.41-.13L14 17.862V6a1 1 0 10-2 0v11.862l-4.36-3.63a1 1 0 10-1.279 1.54l5.999 5 .15.09.13.07a1 1 0 00.72 0l.13-.07.15-.09 5.998-5a1 1 0 00.13-1.41z" fill="#000" fill-rule="nonzero"/></g></symbol><symbol id="trash" viewBox="0 0 16 16"><g fill="currentColor" fill-rule="evenodd"><path d="M6 13a1 1 0 001-1V7a1 1 0 10-2 0v5a1 1 0 001 1m4 0a1 1 0 001-1V7a1 1 0 10-2 0v5a1 1 0 001 1"/><path d="M3 14h10V5H3v9zm9.279-12l.334 1H3.387l.334-1h8.558zM15 3h-.279l-.544-1.632A1.998 1.998 0 0012.279 0H3.721c-.863 0-1.625.55-1.897 1.367L1.279 3H1a1 1 0 100 2v9c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V5a1 1 0 100-2z"/></g></symbol></svg><!-- endinject -->
    </div>

</template>

<script>

export default {
  name: 'Icons'
}
</script>
