<template>
  <div v-if="text" class="o-wrapper u-margin-bottom u-margin-bottom-large@tablet">

    <div class="u-margin-bottom">
      <router-link :to="{ name: 'Home' }">
        <IconArrow class="u-margin-right-tiny" /> Overzicht
      </router-link>

      <h1>{{ text.title }}</h1>
    </div>

    <div class="o-layout">
      <div class="o-layout__item u-2/3@tablet u-push-1/6@tablet c-wysiwyg-content" v-html="text.body"></div>
    </div>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { find } from 'lodash'

  import IconArrow from '@/components/icons/IconArrow'

  export default {
    name: 'TextDetail',

    created () {
      if (!this.realm) {
        this.$router.push({ name: 'NotFound' })
      }
    },

    computed: {
      ...mapState({
        texts: state => state.texts
      }),

      realm () {
        return this.$route.meta.realm
      },

      text () {
        return find(this.texts, item => item.realm === this.realm)
      }
    },

    components: {
      IconArrow
    }
  }
</script>
