<template>
    <div class="c-grid" v-if="puzzle">
        <svg :viewBox="`0 0 ${viewBoxWidth} ${viewBoxHeight}`" class="c-grid__svg">
            <PuzzleRow v-for="(item, index) in puzzle.rows" :yIndex="index" :key="index" :row="item"/>
        </svg>

        <PuzzleGridInput/>
    </div>
</template>

<script>
  import { mapState } from 'vuex'

  import PuzzleRow from '@/components/puzzle/PuzzleRow'
  import PuzzleGridInput from '@/components/puzzle/PuzzleGridInput'

  export default {
    name: 'PuzzleGrid',

    data () {
      return {
      }
    },

    methods: {
    },

    computed: {
      ...mapState({
        puzzle: state => state.currentPuzzle,
        gridSettings: state => state.gridSettings
      }),

      viewBoxWidth () {
        return this.gridSettings.cellSize * (this.gridSettings.cellsPerRow + 1) // Add 1 for the letters
      },

      viewBoxHeight () {
        return this.gridSettings.cellSize * this.puzzle.rows.length
      }
    },

    components: {
      PuzzleRow,
      PuzzleGridInput
    }
  }
</script>
