// import Vue from 'vue'
//
import { filter } from 'lodash'
import { initialState } from './state'

/*
 *  RESET STATE
 */
const reset = (state) => {
  // acquire initial state
  const s = initialState()
  Object.keys(s).forEach(key => {
    state[key] = s[key]
  })
}

const resetPuzzleForm = (state) => {
  // acquire initial state
  const s = initialState()
  state.puzzleForm = s.puzzleForm
}

const resetFields = (state, { subform }) => {
  const initial = initialState()
  Object.keys(state[subform].form.fields).map(key => {
    state[subform].form.fields[key].value = initial[subform].form.fields[key].value
    state[subform].form.fields[key].error = ''
  })
}

const setAuthentication = (state, { user }) => {
  const token = user.token

  if (!token) {
    throw Error('Invalid user, no token provided')
  }

  localStorage.setItem('token', token)
  localStorage.setItem('apollo-token', token)
  state.currentUser.isAuthenticated = true
}

const removeAuthentication = (state) => {
  localStorage.removeItem('token')
  state.currentUser.isAuthenticated = false
}

const addPuzzleRowKey = (state, { key }) => {
  state.puzzleForm.form.puzzleRowKeys.push(key)
}

const removePuzzleRowKey = (state, { key }) => {
  state.puzzleForm.form.puzzleRowKeys = filter(state.puzzleForm.form.puzzleRowKeys, (item) => {
    return item !== key
  })
}

const addIdToDelete = (state, { type, id }) => {
  state.idsToDelete[type].push(id)
}

const removeIdToDelete = (state, { type, id }) => {
  state.idsToDelete[type] = filter(state.idsToDelete[type], (item) => {
    return item.id !== id
  })
}

const resetIdsToDelete = (state) => {
  const s = initialState()
  state.idsToDelete = s.idsToDelete
}

// Export
export default {
  reset,
  resetPuzzleForm,
  resetFields,
  setAuthentication,
  removeAuthentication,
  addPuzzleRowKey,
  removePuzzleRowKey,
  addIdToDelete,
  removeIdToDelete,
  resetIdsToDelete
}
