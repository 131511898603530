var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',[_c('div',[(_vm.label)?_c('label',{class:{
          'c-form__label': true,
          'js-page-item': !_vm.isSubfield,
          'js-page-child-item': _vm.isSubfield,
          'u-error': _vm.formError && _vm.error
        },attrs:{"for":_vm.inputId}},[_vm._v("\n      "+_vm._s(_vm._f("capitalize")(_vm.label))+" "),(!_vm.required)?_c('em',[_vm._v("("+_vm._s(_vm.optional)+")")]):_vm._e()]):_vm._e(),_c('div',{class:{
            'c-form__input-wrapper': true,
            'js-page-item': !_vm.isSubfield,
            'js-page-child-item': _vm.isSubfield
          }},[((_vm.readonly === 'false' || _vm.readonly === false) && (_vm.disabled === 'false' || _vm.disabled === false) && _vm.mask)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.maskValue),expression:"maskValue"},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],staticClass:"c-form__input c-form__date",attrs:{"id":_vm.inputId,"type":"text","name":_vm.name,"placeholder":_vm.placeholder,"required":_vm.required,"autofocus":_vm.autofocus === 'true' || _vm.autofocus === true ? 'autofocus' : false},domProps:{"value":(_vm.maskValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.maskValue=$event.target.value},_vm.handleChange],"focusout":_vm.handleChange}}):_c('input',{ref:"input",staticClass:"c-form__input c-form__date",attrs:{"id":_vm.inputId,"type":"text","name":_vm.name,"placeholder":_vm.placeholder,"required":_vm.required,"autofocus":_vm.autofocus === 'true' || _vm.autofocus === true ? 'autofocus' : false,"autocomplete":"off","disabled":_vm.disabled === 'true' || _vm.disabled === true ? true : false,"readonly":_vm.readonly === 'true' || _vm.readonly === true ? true : false},domProps:{"value":_vm.formattedValue(_vm.value)},on:{"input":_vm.handleChange,"focusout":_vm.handleChange}})])]),(_vm.formError && _vm.error)?_c('p',{staticClass:"c-form__error"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),(_vm.helper)?_c('p',{staticClass:"u-margin-top-tiny u-margin-bottom-none"},[_c(_vm.$options.filters.dynamic(("<div>" + _vm.helper + "</div>")) && {template: _vm.$options.filters.dynamic(("<div>" + _vm.helper + "</div>"))},{tag:"component"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }