export const initialState = () => {
    return {
        gridSettings: {
            cellSize: 30,
            cellsPerRow: 13,
            maxRows: 26,
            activeRowIndex: null,
            activeInputIndex: null,
            activeNumberConnector: null,
            connectedCells: [], // Array of objects with structure { numberConnectorId: value, cells: [{ rowIndex: value, inputIndex: value })]
            inputDirection: 'horizontal', // horizontal by default <=> vertical for solution
            inputIsFocused: false
        },
        currentPuzzle: null,
        answers: {}, // Object with structure {rowIndex: { inputIndex: value }}
        invalidRowIndexes: [],
        showSolution: false,
        showPopup: false,
        showIntro: true,
        popupType: null,
        texts: [],

        mobileOperatingSystem: 'unknown' // ios / android / windows / unknown
    }
}
