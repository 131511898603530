<template>
    <div class="u-margin-bottom-small">
        <h2>Cijferlegende</h2>

        <Notification v-if="form.error" :notification="form.error" status="error"/>

        <ul v-if="fieldsWithError.length" class="u-color-error">
            <li v-for="(field, index) in fieldsWithError" :key="index">
                <strong>{{ field.label }}:</strong> {{ field.error }}
            </li>
        </ul>

        <div class="c-number-connectors u-clearfix">
            <div class="c-number-connectors__item" v-for="field in formFields" :key="field.name">
                <FieldInput
                        :fieldId="`number-connector-${field.name}`"
                        :label="field.label"
                        :value="field.value"
                        :required="field.required"
                        :name="field.name"
                        :placeholder="field.placeholder"
                        :type="field.type"
                        :rule="field.rule"
                        :module="module"
                        :subform="subform"
                        :error="field.error"
                        :formError="true"
                        @change="updateNumberConnector(field.name, $event)"
                />
            </div>
        </div>

        <button class="c-button c-button--primary" @click="toggleClearAllPopup">
            Wis cijferlegende
        </button>

        <Popup v-if="showPopup && popupType === popupTypeClearNumberConnectors">
            <div class="o-flex o-flex--column o-flex--align-center">
                <p>
                    Bent u zeker dat u de cijferlegende wil wissen?
                </p>

                <button class="c-button c-button--primary u-margin-bottom-small" @click="clearAll">
                    Wis cijferlegende
                </button>

                <button class="c-button c-button--ghost u-margin-bottom-small" @click="toggleClearAllPopup">
                    Cijferlegende behouden
                </button>
            </div>
        </Popup>
    </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { clone, filter, findIndex } from 'lodash'

  import Popup from '@/components/Popup'
  import Notification from '@/components/Notification'
  import FieldInput from '@/components/forms/FieldInput'

  export default {
    name: 'AdminPuzzleNumberConnectorsForm',

    data () {
      return {
        module: 'admin',
        subform: 'puzzleNumberConnectorsForm',
        popupTypeClearNumberConnectors: 'popup_type_clear_number_connectors'
      }
    },

    methods: {
      updateNumberConnector (letter, value) {
        if (!this.validateForm()) {
          return
        }

        const puzzle = this.puzzle
        let legend = {}
        let index = findIndex(puzzle.legends, (item) => {
          return item.letter === letter
        })

        if (index !== -1) {
          legend = clone(puzzle.legends[index])
        }

        legend.letter = letter
        legend.number = value === '' ? '' : parseInt(value)

        this.$store.commit('setPuzzleLegend', { legend, index })
        this.$store.commit('setConnectedCells', { puzzle })
      },

      validateForm () {
        this.setFormError(null)
        const module = this.module
        const subform = this.subform
        const form = this.form
        this.$store.dispatch('forms/validateForm', { module, subform })

        let valid = form.isValid

        let currentValues = []
        const formFields = this.formFields
        const formFieldKeys = Object.keys(formFields)
        for (let i = 0; i < formFieldKeys.length; i++) {
          const field = formFields[formFieldKeys[i]]
          const value = field.value

          if (!value) {
            continue
          }

          if (currentValues.indexOf(value) !== -1) {
            this.$store.commit('setFieldError', { module, subform, field: field.name, error: 'De waarden in de cijferlegende moeten uniek zijn' })

            valid = false
          }

          currentValues.push(value)
        }

        return valid
      },

      setFormError (error) {
        const module = this.module
        const subform = this.subform
        this.$store.commit('setFormError', { module, subform, error })
      },

      toggleClearAllPopup () {
        this.$store.commit('togglePopup', { type: this.popupTypeClearNumberConnectors })
      },

      clearAll () {
        this.$store.commit('admin/resetFields', { subform: this.subform })
        this.$store.commit('setPuzzleLegends', { legends: [] })
        this.toggleClearAllPopup()
      }
    },

    computed: {
      ...mapState({
        puzzle: state => state.currentPuzzle,
        gridSettings: state => state.gridSettings,
        showPopup: state => state.showPopup,
        popupType: state => state.popupType
      }),

      ...mapState('admin', {
        form: state => state.puzzleNumberConnectorsForm.form,
        formFields: state => state.puzzleNumberConnectorsForm.form.fields
      }),

      fieldsWithError () {
        // console.log(filter(this.formFields, (item) => {
        //   return item.error && item.error !== ''
        // }))
        return filter(this.formFields, (item) => {
          return item.error && item.error !== ''
        })
      }
    },

    components: {
      Popup,
      Notification,
      FieldInput
    }
  }
</script>
