var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'c-notification': true,
    'c-notification--warning': _vm.status === 'warning',
    'c-notification--error': _vm.status === 'error',
    'c-notification--info': _vm.status === 'info',
    'c-notification--success': _vm.status === 'success',
    'c-notification--snackbar': _vm.snackbar
  }},[(!!_vm.$slots.default)?_vm._t("default"):(typeof _vm.notification === 'string' || _vm.notification instanceof String)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.notification)}}):_c('ul',_vm._l((_vm.notification),function(item){return _c('li',{key:item.id},[_vm._v("\n      "+_vm._s(item)+"\n    ")])}))],2)}
var staticRenderFns = []

export { render, staticRenderFns }