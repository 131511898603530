<template>
    <div class="o-wrapper o-wrapper--max-width u-margin-top u-margin-bottom u-margin-bottom-large@tablet" v-if="isAuthenticated">

        <Notification
            v-if="notification.message"
            :notification="notification.message"
            :status="notification.status"
            :snackbar="true"
            class="u-margin-bottom-small"
        />

        <div class="o-flex o-flex--justify-space-between">
            <div class="u-margin-bottom">
                <h1>Karels Crypto</h1>

                <h2 v-if="$route.meta.title">
                    {{ $route.meta.title }}
                </h2>
            </div>

            <div class="o-flex o-flex--column">
                <router-link v-if="$route.name !== 'AdminDashboard'" :to="{ name: 'AdminDashboard' }"
                             class="c-button c-button--primary u-margin-bottom-tiny">
                    Terug naar dashboard
                </router-link>

<!--                <router-link  v-if="['AdminPuzzleFormCreate', 'AdminPuzzleFormEdit'].indexOf($route.name) === -1" :to="{ name: 'AdminPuzzleFormCreate' }"-->
<!--                             class="c-button c-button&#45;&#45;secondary u-margin-bottom-tiny">-->
<!--                    + Nieuwe puzzel-->
<!--                </router-link>-->
            </div>
        </div>

        <router-view/>
    </div>
</template>

<script>
  import { mapState } from 'vuex'

  import Notification from '@/components/Notification'

  export default {
    name: 'AdminWrapper',

    computed: {
      ...mapState('admin', {
        notification: state => state.notification,
        isAuthenticated: state => state.currentUser.isAuthenticated
      })
    },

    components: {
      Notification
    }
  }
</script>
