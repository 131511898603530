<template>
  <figure class="c-icon c-icon--trash">

    <svg>
      <use xlink:href="#trash"></use>
    </svg>

  </figure>
</template>

<script>

export default {
  name: 'IconTrash'
}
</script>
