<template>
  <div v-if="!id || currentText">
    <form @submit.prevent="handleSubmit" class="u-border u-padding">
      <Notification v-if="form.error" :notification="form.error" status="error"/>

      <FieldInput
              :fieldId="formFields.title.name"
              :label="formFields.title.label"
              :value="formFields.title.value"
              :required="formFields.title.required"
              :name="formFields.title.name"
              :placeholder="formFields.title.placeholder"
              :type="formFields.title.type"
              :rule="formFields.title.rule"
              :module="module"
              :subform="subform"
              :error="formFields.title.error"
              :formError="form.error"
      />

      <FieldWysiwyg
              :fieldId="formFields.body.name"
              :label="formFields.body.label"
              :value="formFields.body.value"
              :required="formFields.body.required"
              :name="formFields.body.name"
              :placeholder="formFields.body.placeholder"
              :type="formFields.body.type"
              :rule="formFields.body.rule"
              :module="module"
              :subform="subform"
              :error="formFields.body.error"
              :formError="form.error"
      />

      <FieldInput
              :fieldId="formFields.button_text.name"
              :label="formFields.button_text.label"
              :value="formFields.button_text.value"
              :required="formFields.button_text.required"
              :name="formFields.button_text.name"
              :placeholder="formFields.button_text.placeholder"
              :type="formFields.button_text.type"
              :rule="formFields.button_text.rule"
              :module="module"
              :subform="subform"
              :error="formFields.button_text.error"
              :formError="form.error"
      />

      <FieldInput
              :fieldId="formFields.realm.name"
              :label="formFields.realm.label"
              :value="formFields.realm.value"
              :required="formFields.realm.required"
              :name="formFields.realm.name"
              :placeholder="formFields.realm.placeholder"
              :type="formFields.realm.type"
              :rule="formFields.realm.rule"
              :module="module"
              :subform="subform"
              :error="formFields.realm.error"
              :formError="form.error"
      />

      <button class="c-button c-button--primary u-margin-top-small" type="submit">
        Opslaan
      </button>
    </form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import {
    GET_TEXT_BY_ID,
    CREATE_TEXT,
    UPDATE_TEXT
  } from '@/utils/graphql'

  import Notification from '@/components/Notification'
  import FieldInput from '@/components/forms/FieldInput'
  import FieldWysiwyg from '@/components/forms/FieldWysiwyg'

  export default {
    name: 'AdminTextForm',

    data () {
      return {
        module: 'admin',
        subform: 'textForm'
      }
    },

    created () {
      const module = this.module
      const subform = this.subform
      this.$store.commit('resetFields', { module, subform })
      this.$store.commit('resetFormError', { module, subform })
    },

    methods: {
      handleSubmit () {
        this.setFormError(null)
        const module = this.module
        const subform = this.subform
        const form = this.form

        this.$store.dispatch('forms/validateForm', { module, subform })

        if (!form.isValid) {
          this.$SmoothScroll(this.$el, 500)
          return
        }

        const formFields = form.fields

        let variables = {
          title: formFields.title.value,
          body: formFields.body.value,
          realm: formFields.realm.value,
          button_text: formFields.button_text.value
        }

        let mutation = CREATE_TEXT

        const currentText = this.currentText
        if (currentText) {
          variables.id = currentText.id
          mutation = UPDATE_TEXT
        }

        this.$apollo.mutate({
          mutation,
          variables,
          client: 'auth'
          // update: (store, { data }) => {
          //   console.log(store)
          //   console.log(data)
            // // Read the data from our cache for this query.
            // const data = store.readQuery({ query: TAGS_QUERY })
            // // Add our tag from the mutation to the end
            // data.tags.push(addTag)
            // // Write our data back to the cache.
            // store.writeQuery({ query: TAGS_QUERY, data })
          // }
        }).then((data) => {
          // Result
          const notification = {
            message: '✓ De tekst is succesvol opgeslagen',
            status: 'info'
          }
          this.$store.dispatch('setSnackar', { module, notification })

          this.$router.push({ name: 'AdminDashboard' })
        }).catch(() => {
          this.$SmoothScroll(this.$el, 500)
        })

        // this.skipUserQuery = false
        // this.$apollo.queries.user.refetch()
      },

      setFormError (error) {
        const module = this.module
        const subform = this.subform
        this.$store.commit('setFormError', { module, subform, error })
      },

      setFieldValues (data) {
        const mapping = {
          title: data.title,
          body: data.body,
          button_text: data.button_text,
          realm: data.realm
        }

        Object.keys(mapping).forEach((field) => {
          const value = mapping[field]
          this.$store.commit('setFieldValue', {
            module: this.module,
            subform: this.subform,
            field,
            value
          })
        })
      }
    },

    apollo: {
      currentText: {
        query () {
          return GET_TEXT_BY_ID
        },
        variables () {
          return {
            id: parseInt(this.id)
          }
        },
        // Variables: deep object watch
        deep: false,
        fetchPolicy: 'no-cache',
        // We use a custom update callback because
        // the field names don't match
        update: data => data.text,

        result (result, key) {
          const data = result.data.text
          if (!data) {
            return
          }

          this.setFieldValues(data)
        },
        skip () {
          // Only run when updating Text
          return !this.id
        }
      }
    },

    computed: {
      ...mapState('admin', {
        form: state => state.textForm.form,
        formFields: state => state.textForm.form.fields
      }),

      id () {
        return this.$route.params.id
      }
    },

    components: {
      Notification,
      FieldInput,
      FieldWysiwyg
    }
  }
</script>
