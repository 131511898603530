<template>

  <fieldset class="u-margin-none u-height">

    <div class="c-form__time-wrapper">
      <label
        :for="inputId"
        v-if="label"
        :class="{
            'c-form__label': true,
            'u-error': formError && error
          }">
        {{ label|capitalize }} <em v-if="!required">({{ optional }})</em>
      </label>

      <div :class="{
              'u-height': true,
              'c-form__time-error': formErrors && error && errorIndex > -1,
            }">

          <input
            v-if="!readonly && !disabled"
            :id="inputId"
            :class="{
              'c-form__input': true,
              'first': true,
              'c-form__time': true
            }"
            type="text"
            v-model="timeValue"
            v-mask="'#?#:##'"
            :name="name"
            placeholder="08:00"
            :required="required"
            :autofocus="autofocus === 'true' || autofocus === true ? 'autofocus' : false"
            v-on:focusin="handleFocus"
            v-on:input="handleChange"
            v-on:focusout="e => { handleUnfocus(); handleChange(e); }"
          />

          <input
            v-else
            :id="inputId"
            :class="{
              'c-form__input': true,
              'c-disabled': true,
              'c-form__time': true
            }"
            type="text"
            :value="value"
            :name="name"
            placeholder="08:00"
            :required="required"
            :autofocus="autofocus === 'true' || autofocus === true ? 'autofocus' : false"
            :disabled="disabled"
            :readonly="readonly"
            v-on:focusin="handleFocus"
            v-on:focusout="e => { handleUnfocus(); }"
          />

      </div>

        <p v-if="formError && error" class='c-form__error'>{{ error }}</p>

    </div>

  </fieldset>

</template>

<script>

// import moment from 'moment'
// import { findIndex } from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'FieldTime',

  props: [
    'module',
    'subform',
    'inputId',

    'label',

    'name',
    'value',
    'error',
    'autofocus',
    'required',
    'disabled',
    'readonly',

    'formErrors',
    'formError'
  ],

  data () {
    return {
      timeValue: '',
      isFocus: false
    }
  },

  mounted () {
    this.timeValue = this.value
  },

  methods: {
    handleFocus () {
      if ((this.readonly === 'false' || this.readonly === false) && (this.disabled === 'false' || this.disabled === false)) {
        this.isFocus = true
      }
    },

    handleUnfocus () {
      this.isFocus = false
    },

    handleChange (e) {
      let value = e.currentTarget.value

      if (value === '24:00') {
        value = '00:00'
      }

      /*
      *   Set Value
      */
      this.$store.commit('setFieldValue', {
        module: this.module,
        subform: this.subform,
        field: this.name,
        value: value
      })

      /*
      *   Validate Field
      */
      this.$store.dispatch('forms/validateField', {
        module: this.module,
        subform: this.subform,
        field: this.name,
        value: value
      })

      this.$emit('change', value)
    }
  },

  watch: {
    value: {
      handler: function (newValue) {
        this.timeValue = newValue
      },
      immediate: true
    }
  },

  computed: {
    ...mapState({
      optional: state => state.forms.optional
    })
  }
}
</script>
