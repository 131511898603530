import Vue from 'vue'
import { find, map, filter } from 'lodash'

import {
    setFieldValue,
    setFieldError,
    resetFieldError,
    resetFields,
    setNotification,
    resetNotification,
    setFormValid,
    setFormError,
    resetFormError,
    addFieldRule,
    addField
} from './forms/mutations'

const setCurrentPuzzle = (state, { puzzle }) => {
    state.currentPuzzle = puzzle
}

// Used in admin
const setPuzzleRow = (state, { row, index }) => {
    const rows = state.currentPuzzle.rows

    if (index >= 0) {
        Vue.set(rows, index, row)
    } else {
        rows.push(row)
    }
}

const removePuzzleRow = (state, { index }) => {
    state.currentPuzzle.rows.splice(index, 1)
}

// Used in admin
const setPuzzleLegend = (state, { legend, index }) => {
    const legends = state.currentPuzzle.legends

    if (index >= 0) {
        Vue.set(legends, index, legend)
    } else {
        legends.push(legend)
    }
}

const setPuzzleLegends = (state, { legends }) => {
    state.currentPuzzle.legends = legends
}

const setActiveRowIndex = (state, { index }) => {
    state.gridSettings.activeRowIndex = index
}

const setActiveInputIndex = (state, { index }) => {
    state.gridSettings.activeInputIndex = index
}

const setConnectedCells = (state, { puzzle }) => {
    const numberConnectors = puzzle.legends
    let connectedCells = []

    // Iterate through all the rows
    puzzle.rows.forEach((row, rowIndex) => {
        const numberIndexes = map(row.numbers, 'index')
        const answer = row.answer

        if (!numberIndexes) {
            return
        }

        // Find the related number connector for each number index based on the letter in the answer
        numberIndexes.forEach((inputIndex) => {
            const answerLetter = answer[inputIndex]

            if (!answerLetter) {
                return
            }

            const numberConnector = find(numberConnectors, (item) => {
                return item.letter.toLocaleLowerCase() === answerLetter.toLocaleLowerCase()
            })

            if (!numberConnector) {
                return
            }

            const cell = { rowIndex, inputIndex }

            // Check if group has already been added to the output
            let group = find(connectedCells, (item) => {
                return item.numberConnector && item.numberConnector.letter.toLocaleLowerCase() === numberConnector.letter.toLocaleLowerCase()
            })

            // Append this cell to the group
            if (!group) {
                group = {
                    numberConnector,
                    cells: [cell]
                }
                connectedCells.push(group)
            } else {
                group.cells.push(cell)
            }
        })
    })

    state.gridSettings.connectedCells = connectedCells
}

const setActiveNumberConnector = (state, { numberConnector }) => {
    state.gridSettings.activeNumberConnector = numberConnector
}

const resetActive = (state) => {
    state.gridSettings.activeRowIndex = null
    state.gridSettings.activeInputIndex = null
    state.gridSettings.activeNumberConnector = null
}

const setInputDirection = (state, { inputDirection }) => {
    state.gridSettings.inputDirection = inputDirection
}

const setInputIsFocsed = (state, { isFocused }) => {
    state.gridSettings.inputIsFocused = isFocused
}

const initiateAnswers = (state, { puzzle }) => {
    const rows = puzzle.rows
    let answers = {}

    rows.forEach((puzzleRow, rowIndex) => {
        let row = {}
        const answer = puzzleRow.answer

        for (let inputIndex = 0; inputIndex < answer.length; inputIndex++) {
            row[inputIndex] = null
        }

        answers[rowIndex] = row
    })

    state.answers = answers
}

const addAnswer = (state, { answer }) => {
    let row = {}

    for (let inputIndex = 0; inputIndex < answer.length; inputIndex++) {
        row[inputIndex] = null
    }

    const key = Object.keys(state.answers).length

    state.answers[key] = row
}

// const resetAnswer = (state, { rowIndex }) => {
//     state.answers[rowIndex].forEach((puzzleRow, rowIndex) => {
// }

const setAnswerValue = (state, { rowIndex, inputIndex, value }) => {
    if (!state.answers[rowIndex]) {
        state.answers[rowIndex] = {}
    }

    state.answers[rowIndex][inputIndex] = value

    const puzzleId = state.currentPuzzle.id
    if (!puzzleId) {
        return
    }

    localStorage.setItem(`puzzle_answers_${puzzleId}`, JSON.stringify(state.answers))
}

const setAnswersFromLocalStorage = (state, { puzzle }) => {
    const puzzleId = puzzle.id
    if (!puzzleId) {
        return
    }

    const localeStorageAnswersJson = localStorage.getItem(`puzzle_answers_${puzzleId}`)
    if (!localeStorageAnswersJson) {
        return
    }

    const localeStorageAnswers = JSON.parse(localeStorageAnswersJson)
    const stateAnswers = state.answers

    // Make sure the length of the answers match in case the Puzzle changes after publishing
    Object.keys(localeStorageAnswers).forEach((key) => {
        const localeStorageAnswer = localeStorageAnswers[key]
        let stateAnswer = stateAnswers[key]

        if (!localeStorageAnswer || !stateAnswer || Object.keys(localeStorageAnswer).length !== Object.keys(stateAnswer).length) {
            return
        }

        state.answers[key] = localeStorageAnswer
    })
}

const setRowIndexValidation = (state, { rowIndex, isValid }) => {
    if (isValid) {
        state.invalidRowIndexes = filter(state.invalidRowIndexes, item => item !== rowIndex)
        return
    }

    if (state.invalidRowIndexes.indexOf(rowIndex) !== -1) {
        return
    }

    state.invalidRowIndexes.push(rowIndex)
}

const resetRowIndexValidation = (state) => {
    state.invalidRowIndexes = []
}

const setShowSolution = (state, { show }) => {
    state.showSolution = show
}

/*
 *  POPUP
 */
const togglePopup = (state, { type }) => {
    state.showPopup = (type && type === state.popupType) || !type ? !state.showPopup : true
    state.popupType = type
}

const resetPopup = (state) => {
    state.showPopup = false
}

const setTexts = (state, { texts }) => {
    state.texts = texts
}

const setShowIntro = (state, { showIntro }) => {
    state.showIntro = showIntro
}

const setMobileOperatingSystem = (state, { mobileOperatingSystem }) => {
    state.mobileOperatingSystem = mobileOperatingSystem
}

// Export
export default {
    setCurrentPuzzle,
    setPuzzleRow,
    removePuzzleRow,
    setPuzzleLegend,
    setPuzzleLegends,
    setActiveRowIndex,
    setActiveInputIndex,
    setConnectedCells,
    setActiveNumberConnector,
    resetActive,
    setInputDirection,
    setInputIsFocsed,
    initiateAnswers,
    addAnswer,
    setAnswerValue,
    setAnswersFromLocalStorage,
    setShowSolution,
    setRowIndexValidation,
    resetRowIndexValidation,
    togglePopup,
    resetPopup,
    setTexts,
    setShowIntro,
    setMobileOperatingSystem,

    // forms
    setFieldValue,
    setFieldError,
    resetFieldError,
    resetFields,
    setNotification,
    resetNotification,
    setFormValid,
    setFormError,
    resetFormError,
    addFieldRule,
    addField
}
