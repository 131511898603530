<template>
    <div :class="{
        'c-notification': true,
        'c-notification--warning': status === 'warning',
        'c-notification--error': status === 'error',
        'c-notification--info': status === 'info',
        'c-notification--success': status === 'success',
        'c-notification--snackbar': snackbar
      }">

      <!-- text -->
      <slot v-if="!!$slots.default"/>

      <div v-else-if="typeof notification === 'string' || notification instanceof String" v-html="notification"></div>

      <ul v-else>
        <li v-for="item in notification" :key="item.id">
          {{ item }}
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  name: 'Notification',

  props: ['notification', 'status', 'snackbar']
}
</script>
