import moment from 'moment'

const puzzleSolution = (state) => {
  const rows = state.currentPuzzle.rows
  const center = Math.round(state.gridSettings.cellsPerRow / 2) - 1

  let solution = ''

  Object.keys(rows).forEach((key) => {
    const row = rows[key]
    const answer = row.answer

    // Get solution letter
    const solutionIndex = center - row.offset
    solution = solution.concat(answer[solutionIndex])
  })

  return solution
}

const puzzleSolutionDateAvailable = (state) => ({ puzzle }) => {
  if (!puzzle) {
    puzzle = state.currentPuzzle
  }

  if (!puzzle || !puzzle.start_date) {
    return false
  }

  return moment(puzzle.start_date, moment.ISO_8601).add(1, 'week')
}

const puzzleSolutionIsAvailable = (state, getters) => ({ puzzle }) => {
  const dateAvailable = getters.puzzleSolutionDateAvailable({ puzzle })
  const now = moment()

  return now.isSameOrAfter(dateAvailable)
}

export default {
  puzzleSolution,
  puzzleSolutionDateAvailable,
  puzzleSolutionIsAvailable
}
