export const initialState = () => {
  return {
    currentUser: {
      isAuthenticated: false
    },

    notification: {
      message: '',
      status: 'info'
    },

    login: {
      form: {
        fields: {
          email: {
            label: 'E-mailadres',
            name: 'email',
            value: '',
            error: '',
            rule: 'required|email',
            required: true,
            tooltip: ''
          },
          password: {
            label: 'Wachtwoord',
            name: 'password',
            value: '',
            error: '',
            rule: 'required',
            required: true,
            type: 'password',
            tooltip: ''
          }
        },

        error: '',
        isValid: false,
        notification: {
          message: '',
          status: 'info'
        }
      }
    },

    // object with "name of object to delete" as key and an array of ids (.e.g. { row: [1, 3, 5], number: [22, 47] })
    idsToDelete: {
      row: [],
      number: [],
      legend: []
    },

    textForm: {
      form: {
        fields: {
          title: {
            label: 'Titel',
            name: 'title',
            value: '',
            error: '',
            rule: 'required',
            required: true,
            tooltip: ''
          },
          button_text: {
            label: 'Button label',
            name: 'button_text',
            value: '',
            error: '',
            rule: '',
            required: false,
            tooltip: ''
          },
          body: {
            label: 'Inhoud',
            name: 'body',
            value: '',
            error: '',
            rule: 'required',
            required: true,
            tooltip: ''
          },
          realm: {
            label: 'Intern ID',
            name: 'realm',
            value: '',
            error: '',
            rule: 'required',
            required: true,
            tooltip: ''
          }
        },

        error: '',
        isValid: false,
        notification: {
          message: '',
          status: 'info'
        }
      }
    },

    puzzleForm: {
      form: {
        fields: {
          title: {
            label: 'Titel',
            name: 'title',
            value: '',
            error: '',
            rule: 'required',
            required: true,
            tooltip: ''
          },
          start_date: {
            label: 'Start datum',
            name: 'start_date',
            value: '',
            error: '',
            rule: 'required',
            required: true,
            tooltip: ''
          },
          start_hour: {
            label: 'Start uur',
            name: 'start_hour',
            value: '',
            error: '',
            rule: 'required',
            required: true,
            tooltip: ''
          },
          published: {
            label: 'Actief?',
            name: 'published',
            value: '',
            error: '',
            rule: '',
            required: false,
            tooltip: ''
          },
          show_solution: {
            label: 'Toon oplossing in voorbeeldweergave',
            name: 'show_solution',
            value: true,
            error: '',
            rule: '',
            required: false,
            tooltip: ''
          }
        },

        error: '',
        isValid: false,
        notification: {
          message: '',
          status: 'info'
        }
      }
    },

    puzzleRowForm: {
      form: {
        fields: {
          hint: {
            label: 'Hint',
            name: 'hint',
            value: '',
            error: '',
            rule: 'required',
            required: true,
            tooltip: ''
          },
          answer: {
            label: 'Antwoord',
            name: 'answer',
            value: '',
            error: '',
            rule: ['required', 'max:13'],
            required: true,
            tooltip: ''
          },
          offset: {
            label: 'Inspringing',
            name: 'offset',
            value: '',
            error: '',
            rule: ['required', 'integer', 'min:0', 'max:6'],
            type: 'number',
            required: false,
            min: 0,
            max: 6,
            tooltip: ''
          },
          // index: {
          //   label: 'Index',
          //   name: 'index',
          //   value: '',
          //   error: '',
          //   rule: ['numeric'],
          //   required: false,
          //   tooltip: ''
          // },
          has_number_connector: {
            label: 'Cijfercode weergeven in cel',
            name: 'has_number_connector',
            value: '',
            error: '',
            rule: '',
            required: false,
            tooltip: ''
          }
        },

        error: '',
        isValid: false,
        notification: {
          message: '',
          status: 'info'
        }
      }
    },

    puzzleNumberConnectorsForm: {
      form: {
        fields: {
          a: {
            label: 'A',
            name: 'a',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          b: {
            label: 'B',
            name: 'b',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          c: {
            label: 'C',
            name: 'c',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          d: {
            label: 'D',
            name: 'd',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          e: {
            label: 'E',
            name: 'e',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          f: {
            label: 'F',
            name: 'f',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          g: {
            label: 'G',
            name: 'g',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          h: {
            label: 'H',
            name: 'h',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          i: {
            label: 'I',
            name: 'i',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          j: {
            label: 'J',
            name: 'j',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          k: {
            label: 'K',
            name: 'k',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          l: {
            label: 'L',
            name: 'l',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          m: {
            label: 'M',
            name: 'm',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          n: {
            label: 'N',
            name: 'n',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          o: {
            label: 'O',
            name: 'o',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          p: {
            label: 'P',
            name: 'p',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          q: {
            label: 'Q',
            name: 'q',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          r: {
            label: 'R',
            name: 'r',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          s: {
            label: 'S',
            name: 's',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          t: {
            label: 'T',
            name: 't',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          u: {
            label: 'U',
            name: 'u',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          v: {
            label: 'V',
            name: 'v',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          w: {
            label: 'W',
            name: 'w',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          x: {
            label: 'X',
            name: 'x',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          y: {
            label: 'Y / IJ',
            name: 'y',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          },
          z: {
            label: 'Z',
            name: 'z',
            value: '',
            error: '',
            rule: ['integer'],
            required: false,
            tooltip: ''
          }
        },

        error: '',
        isValid: false,
        notification: {
          message: '',
          status: 'info'
        }
      }
    }
  }
}
