<template>
    <Fragment>
        <PuzzleCell
            class="c-grid__cell--letter"
            :class="{'c-grid__cell--letter--active': gridSettings.inputIsFocused && yIndex === gridSettings.activeRowIndex}"
            :xIndex="0"
            :yIndex="yIndex"
            textX="12"
            :text="$options.filters.numberToLetter(yIndex)"
        />

        <PuzzleCellValue
            v-for="(letter, key) in row.answer"
            :key="key"
            :row="row"
            :inputIndex="key"
            :yIndex="yIndex"
            :class="{ 'c-grid__cell--invalid': isInvalid }"
        />
    </Fragment>
</template>

<script>
  import { mapState } from 'vuex'
  import { Fragment } from 'vue-fragment' /* Special element ignored by DOM */

  import PuzzleCell from '@/components/puzzle/PuzzleCell'
  import PuzzleCellValue from '@/components/puzzle/PuzzleCellValue'

  export default {
    name: 'PuzzleRow',

    props: ['row', 'yIndex'],

    components: {
      Fragment,
      PuzzleCell,
      PuzzleCellValue
    },

    computed: {
      ...mapState({
        gridSettings: state => state.gridSettings,
        invalidRowIndexes: state => state.invalidRowIndexes
      }),

      isInvalid () {
        return this.invalidRowIndexes.indexOf(this.yIndex) !== -1
      }
    }
  }
</script>
