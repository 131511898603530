import { render, staticRenderFns } from "./PuzzleHint.vue?vue&type=template&id=12c93612&"
import script from "./PuzzleHint.vue?vue&type=script&lang=js&"
export * from "./PuzzleHint.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "PuzzleHint.vue"
export default component.exports