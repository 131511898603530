<template>
    <div class="u-border u-border-top-none u-margin-bottom-small u-padding-small">
        <Notification v-if="form.error" :notification="form.error" status="error"/>

        <FieldInput
                :fieldId="formFields.hint.name"
                :label="formFields.hint.label"
                :value="formFields.hint.value"
                :required="formFields.hint.required"
                :name="formFields.hint.name"
                :placeholder="formFields.hint.placeholder"
                :type="formFields.hint.type"
                :rule="formFields.hint.rule"
                :module="module"
                :subform="subform"
                :error="formFields.hint.error"
                :formError="isDirty.hint"
                @change="updatePuzzleRow('hint', $event)"
        />

        <FieldInput
                :fieldId="formFields.answer.name"
                :label="formFields.answer.label"
                :value="formFields.answer.value"
                :required="formFields.answer.required"
                :name="formFields.answer.name"
                :placeholder="formFields.answer.placeholder"
                :type="formFields.answer.type"
                :rule="formFields.answer.rule"
                :module="module"
                :subform="subform"
                :error="formFields.answer.error"
                :formError="isDirty.answer"
                @change="updatePuzzleRow('answer', $event)"
        />

        <FieldInput
                :fieldId="formFields.offset.name"
                :label="formFields.offset.label"
                :value="formFields.offset.value"
                :required="formFields.offset.required"
                :name="formFields.offset.name"
                :placeholder="formFields.offset.placeholder"
                :type="formFields.offset.type"
                :rule="formFields.offset.rule"
                :module="module"
                :subform="subform"
                :error="formFields.offset.error"
                :min="formFields.offset.min"
                :max="formFields.offset.max"
                :formError="isDirty.offset"
                @change="updatePuzzleRow('offset', parseInt($event))"
        />

        <FieldCheckbox
                :fieldId="formFields.has_number_connector.name"
                :label="formFields.has_number_connector.label"
                :value="formFields.has_number_connector.value"
                :required="formFields.has_number_connector.required"
                :name="formFields.has_number_connector.name"
                :placeholder="formFields.has_number_connector.placeholder"
                :type="formFields.has_number_connector.type"
                :rule="formFields.has_number_connector.rule"
                :module="module"
                :subform="subform"
                :error="formFields.has_number_connector.error"
                :min="formFields.has_number_connector.min"
                :max="formFields.has_number_connector.max"
                :formError="true"
                @change="toggleNumberConnector($event)"
        />

        <div class="o-flex o-flex--justify-flex-end">
            <button class="c-button c-button--ghost c-button--icon" @click="toggleDeleteRowPopup">
                <IconTrash/> Verwijderen
            </button>
        </div>

        <Popup v-if="showPopup && popupType === popupTypeDeleteRow">
            <div class="o-flex o-flex--column o-flex--align-center">
                <p>
                    Bent u zeker dat u deze rij wil verwijderen?
                </p>

                <button class="c-button c-button--primary u-margin-bottom-small" @click="deleteRow">
                    Rij verwijderen
                </button>

                <button class="c-button c-button--ghost u-margin-bottom-small" @click="toggleDeleteRowPopup">
                    Annuleren
                </button>
            </div>
        </Popup>
    </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { clone, cloneDeep, find, filter } from 'lodash'

  import Notification from '@/components/Notification'
  import Popup from '@/components/Popup'
  import FieldInput from '@/components/forms/FieldInput'
  import FieldCheckbox from '@/components/forms/FieldCheckbox'
  import IconTrash from '@/components/icons/IconTrash'

  export default {
    name: 'AdminPuzzleRowForm',

    data () {
      return {
        module: 'admin',
        subform: 'puzzleRowForm',
        popupTypeDeleteRow: 'popup_type_delete_row',
        isDirty: {
          hint: false,
          answer: false,
          offset: false
        }
      }
    },

    methods: {
      updatePuzzleRow (property, value) {
        this.isDirty[property] = true

        const index = this.activeRowIndex

        const row = clone(this.row)
        row[property] = value

        this.validateRow(row)

        const cellsPerRow = this.gridSettings.cellsPerRow
        if (row.offset + row.answer.length > cellsPerRow) {
          this.setFormError(`Antwoordlengte + inspringing mag niet groter zijn dan ${cellsPerRow}`)
          return false
        }

        if (this.formFields.offset.error) {
          return false
        }

        this.$store.commit('setPuzzleRow', { row, index })
      },

      validateRow (row) {
        this.setFormError(null)
        const module = this.module
        const subform = this.subform
        const form = this.form

        this.$store.dispatch('forms/validateForm', { module, subform })

        if (!form.isValid) {
          return false
        }

        return true
      },

      setFormError (error) {
        const module = this.module
        const subform = this.subform
        this.$store.commit('setFormError', { module, subform, error })
      },

      setFieldValues (data) {
        const mapping = {
          hint: data.hint,
          answer: data.answer,
          offset: data.offset
        }

        Object.keys(mapping).forEach((field) => {
          const value = mapping[field]
          this.$store.commit('setFieldValue', {
            module: this.module,
            subform: this.subform,
            field,
            value
          })
        })
      },

      setHasNumberConnectorValue () {
        const value = this.activeInputHasNumberConnector

        this.$store.commit('setFieldValue', {
          module: this.module,
          subform: this.subform,
          field: 'has_number_connector',
          value
        })
      },

      toggleNumberConnector () {
        const index = this.activeRowIndex
        const row = cloneDeep(this.row)
        const activeInputIndex = this.activeInputIndex

        if (!find(row.numbers, item => item.index === activeInputIndex)) {
          row.numbers.push({ index: activeInputIndex })
        } else {
          row.numbers = filter(row.numbers, (item) => {
            if (item.index !== activeInputIndex) {
              return true
            }

            // If the number has an id, delete it before we save the Puzzle
            if (item.id) {
              this.$store.commit('admin/addIdToDelete', { type: 'number', id: item.id })
            }

            return false
          })
        }

        this.$store.commit('setPuzzleRow', { row, index })
      },

      toggleDeleteRowPopup () {
        this.$store.commit('togglePopup', { type: this.popupTypeDeleteRow })
      },

      deleteRow () {
        const row = this.row

        if (row.id) {
          this.$store.commit('admin/addIdToDelete', { type: 'row', id: parseInt(row.id) })
        }

        this.$store.commit('removePuzzleRow', { index: this.activeRowIndex })
        this.$store.commit('setActiveRowIndex', { index: null })
        this.toggleDeleteRowPopup()
      }
    },

    watch: {
      row: {
        handler: function (data) {
          this.setFormError(null)
          this.setFieldValues(data)
          this.setHasNumberConnectorValue()
        },
        immediate: true
      },
      activeInputIndex: {
        handler: function (data) {
          this.setHasNumberConnectorValue()
        },
        immediate: true
      }
    },

    computed: {
      ...mapState({
        puzzle: state => state.currentPuzzle,
        gridSettings: state => state.gridSettings,
        activeRowIndex: state => state.gridSettings.activeRowIndex,
        activeInputIndex: state => state.gridSettings.activeInputIndex,
        showPopup: state => state.showPopup,
        popupType: state => state.popupType
      }),

      ...mapState('admin', {
        form: state => state.puzzleRowForm.form,
        formFields: state => state.puzzleRowForm.form.fields
      }),

      row () {
        return this.puzzle.rows[this.activeRowIndex]
      },

      activeInputHasNumberConnector () {
        return find(this.row.numbers, item => item.index === this.activeInputIndex) !== undefined
      }
    },

    components: {
      IconTrash,
      Popup,
      Notification,
      FieldInput,
      FieldCheckbox
    }
  }
</script>
