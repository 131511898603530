<template>
    <div class="c-popup" :class="[ popupSizeClass ]">
        <div class="c-popup__backdrop" @click="handleClose"></div>

        <div class="c-popup__content">
            <button class="c-popup__close c-button c-button--close" @click="handleClose">
                &times;
            </button>

            <slot/>
        </div>
    </div>

</template>

<script>
  export default {
    name: 'Popup',

    props: ['size'],

    data () {
      return {
      }
    },

    methods: {
      handleClose () {
        this.$store.commit('togglePopup', {})
      }
    },

    computed: {
      popupSizeClass () {
        return this.size ? `c-popup--${this.size}` : null
      }
    }
  }
</script>
