import Validator from 'validatorjs' // https://github.com/skaterdav85/validatorjs
import includes from 'lodash/includes'
import convertNumbers from '@/utils/convertNumbers'

/*
*   GENERAL: VALIDATION MESSAGES
*/
const setValidationMessages = ({ state }) => {
    Validator.useLang('nl')
    let messages = Validator.getMessages('nl')
    messages.required = 'Dit veld is verplicht.'
    messages.email = 'Dit is geen correct e-mailadres.'
    messages.unique = 'Dit is geen correct e-mailadres.'
    messages.date = 'Kies een geldige datum (dd-mm-yyyy)'
    messages.integer = 'Dit veld moet een geheel getal zijn.'
    messages.numeric = 'Dit veld moet een getal zijn.'
    messages.min = 'Dit veld moet minstens :min zijn.'
    messages.max = 'Dit veld mag maximaal :max zijn.'
    Validator.setMessages('nl', messages)
}

/*
*   FIELD BASED ACTIONS
*/
const validateField = ({ state, commit, getters, rootState }, { module, subform, field, value, formValidation }) => {
  /*
  * Validate Field
  * This function looks complex, because there are special validation rules
  * and we want to validate each field seperately so the checkmark can do his thing.
  */
  let rules = getters.getFieldRule({ module, subform, field })

  /* Convert numbers to real numbers if we receive a string value */
  if ((rules.indexOf('integer') > -1 || rules.indexOf('numeric') > -1)) {
    if (typeof value === 'string' && value !== '') {
      let valueFloat = parseFloat(convertNumbers(value, 'american'))
      // Only apply the transformation if we receive a number as output
      if (!isNaN(valueFloat)) {
        value = valueFloat
      }
    }
  }

  if (rules) {
    /*
     * Special Validation
     */
    let validation
    let sameRule = false
    let requiredIf = false
    let requiredUnless = false
    let requiredWith = false

    if (rules.constructor === Array) {
      sameRule = rules.find(rule => includes(rule, 'same:'))
      requiredIf = rules.find(rule => includes(rule, 'required_if:'))
      requiredUnless = rules.find(rule => includes(rule, 'required_unless:'))
      requiredWith = rules.find(rule => includes(rule, 'required_with:'))
    }

    if (sameRule) {
      const sameField = sameRule.split('same:')[1]
      const validatorValues = {}
      validatorValues['currentField'] = value
      validatorValues[sameField] = rootState[module][subform].form.fields[sameField].value

      const validatorRules = {}
      validatorRules['currentField'] = rules
      validatorRules[sameField] = rootState[module][subform].form.fields[sameField].rule

      validation = new Validator(validatorValues, validatorRules)
    } else if (requiredIf || requiredUnless || requiredWith) {
      /*
       * REQUIRED IF RULES
       */
      let requiredField
      if (requiredIf) requiredField = requiredIf.split('required_if:')[1]
      else if (requiredUnless) requiredField = requiredUnless.split('required_unless:')[1]
      else if (requiredWith) requiredField = requiredWith.split('required_with:')[1]

      if (requiredField.indexOf(',') > -1) {
        requiredField = requiredField.substr(0, requiredField.indexOf(','))
      }

      if (rules.indexOf('integer') > -1 || rules.indexOf('numeric') > -1) {
        if (typeof value === 'string') {
          value = value.replace(',', '.')
        }
        value = parseFloat(value)
      }

      const validatorValues = {}
      validatorValues['currentField'] = value
      validatorValues[requiredField] = rootState[module][subform].form.fields[requiredField].value ? rootState[module][subform].form.fields[requiredField].value.toString() : rootState[module][subform].form.fields[requiredField].value

      const validatorRules = {}
      validatorRules['currentField'] = rules
      validatorRules[requiredField] = rootState[module][subform].form.fields[requiredField].rule

      validation = new Validator(validatorValues, validatorRules)
    } else {
      // /*
      //  * NORMAL VALIDATION
      //  */
      // if (rules.indexOf('integer') > -1 || rules.indexOf('numeric') > -1) {
      //   if (typeof value === 'string') {
      //     value = value.replace(',', '.')
      //   }
      //   value = parseFloat(value)
      // }

      validation = new Validator({ currentField: value }, { currentField: rules })
    }

    /*
    * Set Field Error
    */
    if (validation.fails()) { // Do validation + check if it fails
      let error = validation.errors.first('currentField')
      commit('setFieldError', { module, subform, field, error }, { root: true })
    } else {
      // reset error
      commit('resetFieldError', { module, subform, field }, { root: true })
    }
  }
}

/*
*   FORM BASED ACTIONS
*/
const validateForm = ({ state, rootState, commit, dispatch }, { module, subform }) => {
  /*
  * Validate every field
  */
  let formIsValid = true
  let fields = subform ? rootState[module][subform].form.fields : rootState[module].form.fields

  for (const field in fields) {
    const fieldObj = fields[field]

    dispatch('validateField', { module, subform, field: fieldObj.name, value: fieldObj.value, formValidation: true })

    const fieldError = fields[field].error
    if (fieldError !== '') {
      formIsValid = false
    }
  }
  /*
  * Set form valid or invalid
  */
  commit('setFormValid', { module, subform, isValid: formIsValid }, { root: true })

  /*
  * Set or reset form error
  */
  if (!formIsValid) commit('setFormError', { module, subform, error: state.validationMessages.global }, { root: true })
  else commit('resetFormError', { module, subform, error: state.validationMessages.global }, { root: true })
}

export default {
  setValidationMessages,
  validateField,
  validateForm
}
