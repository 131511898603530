<template>
    <div class="c-hint-list u-margin-bottom">
        <template v-for="(item, index) in puzzle.rows">
            <button
                :key="index"
                class="c-hint-list__item js-hint-list-item"
                type="button"
                :class="{ 'c-hint-list__item--highlighted': gridSettings.inputIsFocused && gridSettings.activeRowIndex === index }"
                @click="handleClick(index)"
            >
                <div class="c-hint-list__item__letter">
                    {{ index|numberToLetter }}
                </div>

                {{ item.hint|capitalize }}
            </button>

            <AdminPuzzleRowForm
                v-if="showAdminForm && gridSettings.activeRowIndex === index"
                :rowIndex="index"
                :key="`form-${index}`"
            />
        </template>

        <button v-if="showAdminForm && puzzle.rows.length < gridSettings.maxRows" class="c-button c-button--primary u-margin-top-small" @click="addPuzzleRow">
            Rij toevoegen
        </button>
    </div>
</template>

<script>
  import { mapState } from 'vuex'

  import AdminPuzzleRowForm from '@/modules/admin/_components/AdminPuzzleRowForm'

  export default {
    name: 'PuzzleHintList',

    props: ['showAdminForm'],

    mounted () {
      if (this.showAdminForm) {
        this.$store.commit('setActiveRowIndex', { index: 0 })
        return
      }

      document.querySelector('.js-hint-list-item').click()
    },

    methods: {
      handleClick (index) {
        this.$store.commit('setActiveRowIndex', { index: index })
        this.$store.commit('setActiveInputIndex', { index: 0 })
        document.querySelector('.js-grid-input').focus()
      },

      async addPuzzleRow () {
        await this.$store.dispatch('addPuzzleRow', {})

        this.$store.commit('setActiveRowIndex', { index: this.puzzle.rows.length - 1 })
        this.$store.commit('setActiveInputIndex', { index: 0 })
      }
    },

    computed: {
      ...mapState({
        gridSettings: state => state.gridSettings,
        puzzle: state => state.currentPuzzle
      })
    },

    components: {
      AdminPuzzleRowForm
    }
  }
</script>
