<template>

    <div class="c-blocklist">
        <router-link v-for="item in items"
             :to="{ name: 'PuzzleDetail', params: { id: item.id } }"
             :key="item.id"
             class="c-blocklist__item"
        >
            <div class="c-blocklist__item__label">
                <h3>{{ item.title }}</h3>

                <div class="c-date">
                    {{ item.start_date|date }}
                </div>
            </div>
            <div class="c-blocklist__item__arrow">
                <IconArrow direction="right"/>
            </div>
        </router-link>
    </div>

</template>

<script>
  import IconArrow from '@/components/icons/IconArrow'

  export default {
    name: 'PuzzleList',

    props: {
      items: {
        type: Array,
        default: () => []
      }
    },

    components: {
      IconArrow
    }
  }
</script>
