<template>
  <div v-if="!isLoading && currentPuzzle">
    <div v-if="showIntro" class="o-wrapper u-align-center">
      <div class="u-margin-bottom">
        <h1>{{ currentPuzzle.title }}</h1>

        <div class="c-date">
          {{ currentPuzzle.start_date|date }}
        </div>
      </div>

      <div v-if="textIntro" v-html="textIntro.body" class="u-margin-bottom u-margin-bottom-none@tablet"></div>

     <div class="o-flex o-flex--column o-flex--align-center">
       <div class="c-intro__image u-margin-bottom">
         <figure>
           <video class="u-1/1" autoplay muted loop playsinline>
             <source type="video/mp4" :src="`${baseUrl}/assets/images/karelscrypto.mp4`">
           </video>
         </figure>
       </div>

       <button class="c-button c-button--primary" @click="hideIntro">
         Begin aan puzzel
       </button>
     </div>
    </div>

    <div v-else>
      <div class="u-margin-bottom u-margin-bottom-large@tablet u-border-bottom u-border--gray-lighter u-border-bottom-none@tablet">
        <router-link v-if="showBackToAdminButton" :to="{ name: 'AdminDashboard' }" class="u-position-absolute u-position-relative@tablet u-top-0 u-z-index-1 u-margin-left-small">
          <IconArrow class="u-margin-right-tiny" /> Terug naar admin
        </router-link>

        <router-link v-else-if="$route.name !== 'Home'" :to="{ name: 'Home' }" class="u-position-absolute u-position-relative@tablet u-top-0 u-z-index-1 u-margin-left-small">
          <IconArrow class="u-margin-right-tiny" /> Overzicht
        </router-link>

        <Puzzle/>
      </div>

      <div class="o-wrapper">

        <div class="o-layout">
          <div class="o-layout__item u-1/2@tablet">

            <div v-if="!$route.params.id && archive && archive.length && lastArchivedPuzzleWithSolution && textCtaSolution" class="c-text-block u-margin-bottom-large@tablet">
              <div class="c-text-block__text" v-html="textCtaSolution.body"></div>

              <router-link v-if="textCtaSolution.button_text" :to="{ name: 'PuzzleDetail', params: { id: lastArchivedPuzzleWithSolution.id } }" class="c-button c-button--ghost">
                {{ textCtaSolution.button_text }}
              </router-link>
            </div>

            <div v-if="textCtaRules" class="c-text-block">
              <div class="c-text-block__text" v-html="textCtaRules.body"></div>

              <router-link v-if="textCtaRules.button_text" :to="{ name: 'Rules' }" class="c-button c-button--ghost">
                {{ textCtaRules.button_text }}
              </router-link>
            </div>

          </div>

<!--          <div class="o-layout__item u-1/2@tablet">-->
<!--            <div class="u-margin-bottom" v-if="archive && archive.length">-->
<!--              <h3 class="o-flex o-flex&#45;&#45;align-center o-flex&#45;&#45;justify-space-between">-->
<!--                Archief-->

<!--                <router-link :to="{ name: 'Archive' }">-->
<!--                  Bekijk archief <IconArrow direction="right" class="u-margin-left-tiny"/>-->
<!--                </router-link>-->
<!--              </h3>-->

<!--              <PuzzleList :items="archive"/>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { find, cloneDeep } from 'lodash'
  import moment from 'moment'

  import {
    GET_HOME_PUZZLE,
    GET_PUZZLE_BY_ID,
    GET_PUZZLES
  } from '@/utils/graphql'

  import Puzzle from '@/components/puzzle/Puzzle'
  import IconArrow from '@/components/icons/IconArrow'

  const baseUrl = process.env.VUE_APP_URL

  export default {
    name: 'PuzzleDetail',

    data () {
      return {
        baseUrl,
        isLoading: true
      }
    },

    created () {
      if (this.$route.query['skip-intro']) {
        this.hideIntro()
      }
    },

    watch: {
      $route: {
        handler: function (newValue) {
          this.isLoading = true
          this.$store.commit('setCurrentPuzzle', { puzzle: null })
          this.$store.commit('setShowSolution', { show: false })
        },
        immediate: true
      },

      currentPuzzle: function (puzzle) {
        const now = moment()
        const dateAvailable = moment(puzzle.start_date, moment.ISO_8601)

        if (!this.isAuthenticated && (!now.isSameOrAfter(dateAvailable) || !puzzle.published)) {
          this.$router.push({ name: 'NotFound' })
        }

        this.$store.dispatch('initiatePuzzle', { puzzle })
          .then(() => {
            this.isLoading = false
          })
      }
    },

    methods: {
      hideIntro () {
        this.$store.commit('setShowIntro', { showIntro: false })
      }
    },

    computed: {
      ...mapState({
        texts: state => state.texts,
        showIntro: state => state.showIntro
      }),

      ...mapState('admin', {
        isAuthenticated: state => state.currentUser.isAuthenticated
      }),

      archive () {
        let puzzles = this.puzzles ? [...this.puzzles] : []

        if (!this.currentPuzzle || !this.currentPuzzle.id) {
          return puzzles
        }

        puzzles = puzzles.filter((item) => {
          return item.id !== this.currentPuzzle.id
        })

        return puzzles
      },

      textIntro () {
        return find(this.texts, item => item.realm === 'intro')
      },

      lastArchivedPuzzleWithSolution () {
        return find(this.archive, (item) => {
          return this.$store.getters.puzzleSolutionIsAvailable({ puzzle: item })
        })
      },

      textCtaSolution () {
        let text = find(this.texts, item => item.realm === 'cta_solution')

        if (!text) {
          return null
        }

        const lastArchivedPuzzleWithSolution = this.lastArchivedPuzzleWithSolution

        if (!lastArchivedPuzzleWithSolution) {
          return null
        }

        text = cloneDeep(text)
        const date = this.$options.filters.date(lastArchivedPuzzleWithSolution.start_date, 'D MMMM').toLowerCase()
        text.body = text.body.replace('{{ date }}', date)

        return text
      },

      textCtaRules () {
        return find(this.texts, item => item.realm === 'cta_rules')
      },

      showBackToAdminButton () {
        return this.$route.params.preview
      }
    },

    // Apollo-specific options
    apollo: {
      // Advanced query with parameters
      // The 'variables' method is watched by vue
      currentPuzzle: {
        query () {
          const id = this.$route.params.id
          return id ? GET_PUZZLE_BY_ID : GET_HOME_PUZZLE
        },
        variables () {
          let variables = {}

          const id = this.$route.params.id
          if (id) {
            variables.id = parseInt(id)
          }

          return variables
        },
        error () {
          this.$router.push({ name: 'NotFound' })
        },
        // Variables: deep object watch
        deep: false,
        fetchPolicy: 'no-cache',
        // We use a custom update callback because
        // the field names don't match
        update: data => data.published_puzzle || data.puzzle
      },
      puzzles: {
        query () {
          return GET_PUZZLES
        },
        variables () {
          let variables = {
            published: true,
            page: 1,
            limit: 6
          }

          return variables
        },
        deep: false,
        update: data => data.puzzles.data
      }
    },

    components: {
      Puzzle,
      IconArrow
    }
  }
</script>
