import { getAllTranslations, getJsonData } from './_api'
import { find } from 'lodash'

const getTranslations = ({ commit }) => {
    return new Promise((resolve, reject) => {
        getAllTranslations({ lang: 'nl' })
        .then(result => {
            return resolve(result)
        })
        .catch(error => {
            const message = error.response.data.message
            reject(new Error(message))
        })
    })
}

const getJson = ({ commit }, { filename }) => {
    return new Promise((resolve, reject) => {
        getJsonData({ filename })
          .then(result => {
              return resolve(result)
          })
          .catch(error => {
              const message = error.response.data.message
              reject(new Error(message))
          })
    })
}

const initiatePuzzle = ({ commit }, { puzzle }) => {
    commit('setCurrentPuzzle', { puzzle })
    commit('setConnectedCells', { puzzle })
    commit('initiateAnswers', { puzzle })
    commit('setAnswersFromLocalStorage', { puzzle })
    commit('resetRowIndexValidation')
}

const addPuzzleRow = ({ commit }, { dummyData }) => {
    let row = {
        hint: '',
        answer: '',
        offset: 0,
        numbers: [],
        index: 0
    }

    if (dummyData) {
        row.answer = 'KARELSCRYPTO'
    }

    commit('addAnswer', { answer: row.answer })
    commit('setPuzzleRow', { row })
}

const clearAnswerRow = ({ state, commit }, { rowIndex, clearConnectedValues }) => {
    const answer = state.answers[rowIndex]
    const indexes = Object.keys(answer)

    const row = state.currentPuzzle.rows[rowIndex]

    indexes.forEach((inputIndex) => {
        if (!clearConnectedValues) {
            const hasConnectorIndex = find(row.numbers, (item) => item.index === parseInt(inputIndex))

            if (hasConnectorIndex) {
                return
            }
        }

        commit('setAnswerValue', { rowIndex, inputIndex, value: null })
    })
}

const clearAnswers = ({ state, dispatch }) => {
    const answers = state.answers
    const indexes = Object.keys(answers)

    indexes.forEach((rowIndex) => {
        dispatch('clearAnswerRow', { rowIndex, clearConnectedValues: true })
    })
}

const validateSolution = ({ state, getters, commit }) => {
    // Validate answers:
    commit('resetRowIndexValidation')
    let correctAnswers = true
    const rows = state.currentPuzzle.rows
    const center = Math.round(state.gridSettings.cellsPerRow / 2) - 1
    const inputAnswers = state.answers
    let inputSolutionObject = {}

    Object.keys(rows).forEach((key) => {
        const row = rows[key]
        const answer = row.answer
        const inputAnswerObject = inputAnswers[key]
        const inputAnswer = answerObjectToString(inputAnswerObject)

        if (answer.toUpperCase() !== inputAnswer.toUpperCase()) {
            // console.log(`Answer '${inputAnswer}' expected to be '${answer}'`)
            commit('setRowIndexValidation', { rowIndex: parseInt(key), isValid: false })
            correctAnswers = false
        }

        // Get solution letter
        const solutionIndex = center - row.offset
        inputSolutionObject[key] = inputAnswerObject[solutionIndex]
    })

    // Validate Solution:
    const solution = getters.puzzleSolution
    const inputSolution = answerObjectToString(inputSolutionObject)
    const correctSolution = solution.toUpperCase() === inputSolution.toUpperCase()

    // if (!correctSolution) {
    //     console.log(`Solution '${inputSolution}' expected to be '${solution}'`)
    // }

    // Return result
    return {
        correctSolution,
        correctAnswers
    }
}

const answerObjectToString = (object) => {
    let string = ''

    Object.keys(object).forEach((key) => {
        const letter = object[key]

        if (letter) {
            string += letter
        }
    })

    return string
}

const setSnackar = ({ commit }, { module, subform, isForm, notification }) => {
    commit('setNotification', { module, subform, isForm, notification })

    setTimeout(() => {
        commit('resetNotification', { module, subform, isForm })
    }, 5000)
}

// Export
export default {
    getTranslations,
    getJson,
    initiatePuzzle,
    addPuzzleRow,
    clearAnswerRow,
    clearAnswers,
    validateSolution,
    setSnackar
}
