<template>
    <div class="c-hint__wrapper">
        <div class="c-hint" v-if="activePuzzleRow">
            <div class="c-hint__letter">
                {{ activeRowIndex|numberToLetter }}
            </div>

            {{ activePuzzleRow.hint|capitalize }}
        </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'PuzzleHint',

    methods: {
      handleClick (index) {
        this.$store.commit('setActiveRowIndex', { index: index })
        this.$store.commit('setActiveInputIndex', { index: 0 })
      }
    },

    computed: {
      ...mapState({
        activeRowIndex: state => state.gridSettings.activeRowIndex,
        puzzle: state => state.currentPuzzle
      }),

      activePuzzleRow () {
        const activeRowIndex = this.activeRowIndex

        if (activeRowIndex === null) {
          return null
        }

        return this.puzzle.rows[activeRowIndex]
      }
    }
  }
</script>
