<template>
  <div>
    <AdminPuzzleList class="u-margin-bottom"/>

    <AdminTextList class="u-margin-bottom"/>
  </div>
</template>

<script>
  import AdminPuzzleList from '@/modules/admin/_components/AdminPuzzleList'
  import AdminTextList from '@/modules/admin/_components/AdminTextList'

  export default {
    name: 'AdminDashboard',

    components: {
      AdminPuzzleList,
      AdminTextList
    }
  }
</script>
