import Vue from 'vue'
import Router from 'vue-router'

import PuzzleDetail from '@/views/PuzzleDetail.vue'
import PuzzleArchive from '@/views/PuzzleArchive.vue'
import TextDetail from '@/views/TextDetail.vue'
import NotFound from '@/views/NotFound.vue'

import AdminWrapper from '@/modules/admin/AdminWrapper.vue'
import AdminDashboard from '@/modules/admin/AdminDashboard.vue'
import AdminLogin from '@/modules/admin/AdminLogin.vue'
import AdminLogout from '@/modules/admin/AdminLogout.vue'
import AdminPuzzleForm from '@/modules/admin/AdminPuzzleForm.vue'
import AdminTextForm from '@/modules/admin/AdminTextForm.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
        path: '/',
        name: 'Home',
        component: PuzzleDetail,
        meta: {
            requiresAuth: false
        }
    },

    {
        path: '/puzzle/:id',
        name: 'PuzzleDetail',
        component: PuzzleDetail,
        meta: {
          requiresAuth: false
        }
    },

    {
        path: '/archief',
        name: 'Archive',
        component: PuzzleArchive,
        meta: {
          requiresAuth: false
        }
    },

    {
        path: '/spelregels',
        name: 'Rules',
        component: TextDetail,
        meta: {
          requiresAuth: false,
          realm: 'rules'
        }
    },

    // ADMIN

    {
      path: '/admin/login',
      name: 'AdminLogin',
      component: AdminLogin,
      meta: {
        requiresAuth: false
      }
    },

    {
      path: '/',
      name: 'AdminWrapper',
      component: AdminWrapper,
      meta: {
        requiresAuth: true
      },

      children: [
        {
          path: '/admin',
          name: 'AdminDashboard',
          component: AdminDashboard,
          meta: {
            requiresAuth: true,
            title: 'Dashboard'
          }
        },
        {
          path: '/admin/logout',
          name: 'AdminLogout',
          component: AdminLogout,
          meta: {
            requiresAuth: true,
            title: 'Afmelden'
          }
        },
        {
          path: '/admin/puzzle/new',
          name: 'AdminPuzzleFormCreate',
          component: AdminPuzzleForm,
          meta: {
            requiresAuth: true,
            title: 'Puzzel aanmaken'
          }
        },
        {
          path: '/admin/puzzle/:id',
          name: 'AdminPuzzleFormEdit',
          component: AdminPuzzleForm,
          meta: {
            requiresAuth: true,
            title: 'Puzzel bewerken'
          }
        },
        {
          path: '/admin/text/new',
          name: 'AdminTextFormCreate',
          component: AdminTextForm,
          meta: {
            requiresAuth: true,
            title: 'Tekst aanmaken'
          }
        },
        {
          path: '/admin/text/:id',
          name: 'AdminTextFormEdit',
          component: AdminTextForm,
          meta: {
            requiresAuth: true,
            title: 'Tekst bewerken'
          }
        }
      ]
    },

    // 404

    {
      path: '/404',
      name: 'NotFound',
      component: NotFound,
      meta: {
        requiresAuth: false
      }
    },

    {
      path: '*',
      name: '404',
      component: NotFound,
      meta: {
        requiresAuth: false
      }
    }
  ],

  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
