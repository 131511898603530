/*
 *  Used in: everywhere
 *  Converts a number in a specific format
 *  European: 00,00
 *  American: 00.00
 */

export default (text, type) => {
  const americanRegex = /[0-9]([.])[0-9]?/g /* Number contains dot */
  const europeanRegex = /[0-9]([,])[0-9]?/g /* Number contains comma */
  const textString = text.toString()

  if (textString) {
    let numbersText = textString

    /* Check if number contains dots */
    if (americanRegex.test(numbersText) && type === 'european') {
      const matches = textString.match(americanRegex)
      for (let i = 0; i < matches.length; i++) {
        let parts = numbersText.split('.')
        if (parts[1] && parts[1].length > 3) {
          numbersText = numbersText.replace('.0000', '')
        } else {
          numbersText = numbersText.replace('.00', '')
        }

        const europeanNumber = matches[i].replace('.', ',')
        numbersText = numbersText.replace(matches[i], europeanNumber)

        parts = numbersText.split(',')
        if (parts[1] && parts[1].length > 3) {
          numbersText = numbersText.replace(',0000', '')
        } else {
          numbersText = numbersText.replace(',00', '')
        }

        numbersText = numbersText.replace(' ', '')
      }

      return numbersText
    /* Check if number contains comma */
    } else if (europeanRegex.test(numbersText) && type === 'american') {
      const matches = textString.match(europeanRegex)
      for (let i = 0; i < matches.length; i++) {
        let parts = numbersText.split(',')
        if (parts[1] && parts[1].length > 3) {
          numbersText = numbersText.replace(',0000', '')
        } else {
          numbersText = numbersText.replace(',00', '')
        }

        const americanNumber = matches[i].replace(',', '.')
        numbersText = numbersText.replace(matches[i], americanNumber)

        parts = numbersText.split('.')
        if (parts[1] && parts[1].length > 3) {
          numbersText = numbersText.replace('.0000', '')
        } else {
          numbersText = numbersText.replace('.00', '')
        }

        numbersText = numbersText.replace(' ', '')
      }
      return numbersText
    /* Do not convert */
    } else {
      return text
    }
  }
}
