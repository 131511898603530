<template>

  <fieldset>
    <div>
      <label
        :for="inputId"
        v-if="label"
        :class="{
            'c-form__label': true,
            'js-page-item': !isSubfield,
            'js-page-child-item': isSubfield,
            'u-error': formError && error
          }">
        {{ label|capitalize }} <em v-if="!required">({{ optional }})</em>
      </label>

      <div :class="{
              'c-form__input-wrapper': true,
              'js-page-item': !isSubfield,
              'js-page-child-item': isSubfield
            }">
        <input
          v-if="(readonly === 'false' || readonly === false) && (disabled === 'false' || disabled === false) && mask"
          :id="inputId"
          class="c-form__input c-form__date"
          type="text"
          :name="name"
          v-model="maskValue"
          v-mask="mask"
          :placeholder="placeholder"
          :required="required"
          :autofocus="autofocus === 'true' || autofocus === true ? 'autofocus' : false"
          v-on:input="handleChange"
          v-on:focusout="handleChange" />

        <input
          v-else
          :id="inputId"
          class="c-form__input c-form__date"
          ref="input"
          type="text"
          :name="name"
          :value="formattedValue(value)"
          :placeholder="placeholder"
          :required="required"
          :autofocus="autofocus === 'true' || autofocus === true ? 'autofocus' : false"
          v-on:input="handleChange"
          v-on:focusout="handleChange"
          autocomplete="off"
          :disabled="disabled === 'true' || disabled === true ? true : false"
          :readonly="readonly === 'true' || readonly === true ? true : false" />
      </div>

    </div>

    <p v-if="formError && error" class='c-form__error'>{{ error }}</p>
    <p v-if="helper" class="u-margin-top-tiny u-margin-bottom-none">
      <component :is="$options.filters.dynamic(`<div>${helper}</div>`) && {template: $options.filters.dynamic(`<div>${helper}</div>`)}" />
    </p>

  </fieldset>

</template>

<script>
/*
  *
  *  Why is there a hack by hackerwoman in the template to show min and maxdate helpers?
  *  The component does not update when a prop is update but not referenced in the template.
  *
  */

import rome from 'rome'
import moment from 'moment'

export default {
  name: 'FieldDate',

  props: [
    'module',
    'subform',
    'isSubfield', // used for animation staggering
    'inputId',

    'label',
    'placeholder',

    'name',
    'value',
    'error',
    'autofocus',
    'required',
    'disabled',
    'readonly',

    'initDate',
    'minDate',
    'maxDate',
    'mask',
    'hidePicker',

    'tooltip',
    'helper',
    'formError',
    'reactive'
  ],

  data () {
    return {
      maskValue: '',
      dateIsLongEnough: false,
      datePicker: '',
      styles: {
        back: 'c-datepicker__prev',
        container: 'c-datepicker__wrapper',
        date: 'c-datepicker',
        dayBody: 'c-datepicker__days',
        dayBodyElem: 'c-datepicker__day',
        dayNextMonth: 'c-datepicker__day--next',
        dayPrevMonth: 'c-datepicker__day--prev',
        dayConcealed: 'rd-day-concealed',
        dayDisabled: 'c-datepicker__day--disabled',
        dayHead: 'c-datepicker__head-days',
        dayHeadElem: 'c-datepicker__head',
        dayRow: 'c-datepicker__days-row',
        dayTable: 'c-datepicker__calendar',
        month: 'c-datepicker__container',
        monthLabel: 'c-datepicker__month',
        next: 'c-datepicker__next',
        positioned: 'rd-container-attachment',
        selectedDay: 'c-datepicker__day--selected',
        selectedTime: 'rd-time-selected',
        time: 'rd-time',
        timeList: 'rd-time-list',
        timeOption: 'rd-time-option'
      }
    }
  },

  mounted () {
    moment.locale('nl-be')
    this.checkDateLength(this.value)

    if (this.disabled !== true && this.readonly !== true && this.hidePicker !== true) {
      this.setDatePicker()
    }

    if (this.mask) {
      this.maskValue = this.formattedValue(this.value)
    }
  },

  methods: {
    checkDateLength (value) {
      const date = this.formattedValue(value)
      this.dateIsLongEnough = (date !== '' && date.length === 10)
    },

    formattedValue (value) {
      if (value !== '') {
        let date = moment(value, moment.ISO_8601).format('DD/MM/YYYY')
        return date
      } else {
        return ''
      }
    },

    setDatePicker (initialValue) {
      const $inputField = this.$refs.input

      const options = {
        initialValue: initialValue,
        time: false,
        autoClose: true,
        min: this.minDate,
        max: this.maxDate,
        inputFormat: 'DD/MM/YYYY',
        // appendTo: $inputField.parentNode,
        styles: this.styles
      }

      if (!this.datePicker) {
        this.datePicker = rome($inputField, options)
      } else {
        this.datePicker.options(options)
      }

      rome($inputField).on('data', () => {
        const changeEvent = new Event('change')
        $inputField.dispatchEvent(changeEvent)
      })
    },

    handleChange (e) {
      let value = e.target.value
      this.setValue(value)
    },

    setValue (value) {
      /*
       *  CONVERT VALUE TO JS DATE FOR VALIDATION
       *  [1] Only format the value, when it's not an empty string
       *  [2] Only format and set value, if it's a date or if its not empty
       */

      if (value.length === 10 || value === '') { /* [2] */
        let date = ''
        if (value !== '') { /* [1] */
          date = moment(value, 'DD/MM/YYYY')
          date = date.format() // to ISO format
        }

        /*
        *   Set Value
        */
        this.$store.commit('setFieldValue', {
          module: this.module,
          subform: this.subform,
          field: this.name,
          value: date
        })

        /*
        *   Validate Field
        */
        this.$store.dispatch('forms/validateField', {
          module: this.module,
          subform: this.subform,
          field: this.name,
          value: date
        })

        if (value === '') {
          this.dateIsLongEnough = false
        } else {
          this.dateIsLongEnough = true
        }
      } else {
        this.dateIsLongEnough = false
      }
    }
  }
}
</script>
