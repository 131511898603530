<template>
  <div id="app">

    <Icons />

    <router-view></router-view>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import {
  GET_TEXTS,
  WHO_AM_I
} from '@/utils/graphql'

import Icons from '@/components/icons/Icons'

export default {
  name: 'app',

  async created () {
    const token = localStorage.getItem('token')
    if (token) {
      this.$store.commit('admin/setAuthentication', { user: { token } })
    }

    await this.getAuthenticatedUser()

    this.handleAuth()
    this.handleAuthRouting()
    this.$store.dispatch('forms/setValidationMessages')

    window.addEventListener('resize', this.setVerticalHeightVariable)
    this.setVerticalHeightVariable()
  },

  methods: {
    setVerticalHeightVariable () {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },

    toggleRootElementClass (className, boolean) {
      const rootElement = document.documentElement

      if (!rootElement) {
        return
      }

      rootElement.classList.toggle(className, boolean)
    },

    handleAuth () {
      const isAuthenticated = this.isAuthenticated

      if (this.$route.meta.requiresAuth && !isAuthenticated) {
        this.$router.push({ name: 'AdminLogin' })
      }
    },

    handleAuthRouting () {
      this.$router.beforeEach((to, from, next) => {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (to.matched.some(record => record.meta.requiresAuth) && !this.isAuthenticated) {
          next({
            name: 'AdminLogin'
          })
        } else {
          next() // make sure to always call next()!
        }
      })
    },

    async getAuthenticatedUser () {
      return this.$apollo.mutate({
        mutation: WHO_AM_I,
        // variables,
        client: 'auth'
      }).catch(() => {
        this.$store.commit('admin/removeAuthentication')
      })
    }
  },

  watch: {
    popupActive: function (newValue) {
      this.toggleRootElementClass('u-overflow-hidden', newValue)
    }
  },

  // Apollo-specific options
  apollo: {
    texts: {
      query () {
        return GET_TEXTS
      },
      deep: false,
      result (result, key) {
        this.$store.commit('setTexts', { texts: result.data.texts })
      }
    }
  },

  computed: {
    ...mapState({
      showPopup: state => state.showPopup,
      popupType: state => state.popupType
    }),

    ...mapState('admin', {
      isAuthenticated: state => state.currentUser.isAuthenticated
    }),

    popupActive () {
      return this.showPopup && this.popupType
    }
  },

  components: {
    Icons
  }
}
</script>

<style lang="scss">
   @import '../_frontend/src/assets/_scss/main.scss';
</style>
