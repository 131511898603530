<template>
    <div class="c-grid__input__wrapper" :style="style">
        <input
            class="c-grid__input js-grid-input"
            ref="input"
            name="grid_input"
            type="text"
            autocomplete="off"
            spellcheck="false"
            autocorrect="off"
            autofocus
            @focus="handleFocus(true)"
            @focusout="!isAdminPuzzleForm ? handleFocus(false) : null"
            @input="handleInput"
            @keydown.tab="handleTab"
            @keydown.delete="handleDelete"
            @keydown.enter.prevent="setNextRowActive"
        >
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { find } from 'lodash'
import isInViewport from '@/utils/isInViewport'

  export default {
    name: 'PuzzleGridInput',

    methods: {
      handleInput (e) {
        let value = e.target.value
        value = value.length ? value[0] : ''
        const rowIndex = this.activeRowIndex
        const inputIndex = this.activeInputIndex
        this.setAnswerValue(rowIndex, inputIndex, value)
        this.resetRowValidation()
        this.setActiveConnectedCellValues(value)
        // this.value = ''
        this.$refs.input.value = ''

        // Fix for android sending keycode 229 on keydown when it's still processing the keydown event
        // Somehow blurring and refocusing solves this problem (https://lunargravity.atlassian.net/browse/DS1486-58)
        // Might give problems on iOS so we apply only on android devices
        if (this.mobileOperatingSystem === 'android') {
            this.$refs.input.blur()
            this.$refs.input.focus()
        }

        this.setNextCellActive()
      },

      handleTab (e) {
        if (e.shiftKey) {
          if (this.setPreviousRowActive()) {
            e.preventDefault()
          }

          return
        }

        if (this.setNextRowActive()) {
            e.preventDefault()
        }
      },

      handleDelete (e) {
        if (this.inputValue) {
          const rowIndex = this.activeRowIndex
          const inputIndex = this.activeInputIndex
          this.setAnswerValue(rowIndex, inputIndex, '')
          this.setActiveConnectedCellValues('')
        }

        this.setPreviousCellActive()
      },

      handleFocus (isFocused) {
        if (isFocused) {
          this.$nextTick(() => {
            if (isInViewport(this.$el)) {
              return
            }

            this.$SmoothScroll(this.$el, 500)
          })
        }

        this.$store.commit('setInputIsFocsed', { isFocused })
      },

      setNextCellActive () {
        let hasNextInput

        // Vertical for solution
        if (this.gridSettings.inputDirection === 'vertical') {
          hasNextInput = this.activeRowIndex < this.puzzle.rows.length - 1
          if (!hasNextInput) {
            return false
          }

          const nextRowIndex = this.activeRowIndex + 1
          const nextRow = this.puzzle.rows[nextRowIndex]
          const solutionIndex = this.getSolutionInputIndex(nextRow)

          this.$store.commit('setActiveRowIndex', { index: nextRowIndex })
          this.$store.commit('setActiveInputIndex', { index: solutionIndex })
          return
        }

        // Horizontal by default
        hasNextInput = this.activeInputIndex < this.activeRow.answer.length - 1
        if (hasNextInput) {
          this.$store.commit('setActiveInputIndex', { index: this.activeInputIndex + 1 })
        }
      },

      setPreviousCellActive () {
        let hasPreviousInput

        // Vertical for solution
        if (this.gridSettings.inputDirection === 'vertical') {
          hasPreviousInput = this.activeRowIndex > 0
          if (!hasPreviousInput) {
            return false
          }

          const previousRowIndex = this.activeRowIndex - 1
          const previousRow = this.puzzle.rows[previousRowIndex]
          const solutionIndex = this.getSolutionInputIndex(previousRow)

          this.$store.commit('setActiveRowIndex', { index: previousRowIndex })
          this.$store.commit('setActiveInputIndex', { index: solutionIndex })
          return
        }

        // Horizontal by default
        hasPreviousInput = this.activeInputIndex > 0
        if (hasPreviousInput) {
          this.$store.commit('setActiveInputIndex', { index: this.activeInputIndex - 1 })
        }
      },

      setNextRowActive () {
        const hasNextRow = this.activeRowIndex < this.puzzle.rows.length - 1
        if (!hasNextRow) {
          return false
        }

        this.$store.commit('setActiveRowIndex', { index: this.activeRowIndex + 1 })
        this.$store.commit('setActiveInputIndex', { index: 0 })

        return true
      },

      setPreviousRowActive () {
        const hasPreviousRow = this.activeRowIndex > 0
        if (!hasPreviousRow) {
          return false
        }

        this.$store.commit('setActiveRowIndex', { index: this.activeRowIndex - 1 })
        this.$store.commit('setActiveInputIndex', { index: 0 })

        return true
      },

      setActiveConnectedCellValues (value) {
        this.activeConnectedCells.forEach((item) => {
          this.setAnswerValue(item.rowIndex, item.inputIndex, value)
        })
      },

      setAnswerValue (rowIndex, inputIndex, value) {
        value = value.toUpperCase()
        this.$store.commit('setAnswerValue', { rowIndex, inputIndex, value })
      },

      resetRowValidation () {
        if (this.gridSettings.inputDirection !== 'vertical') {
          this.$store.commit('setRowIndexValidation', { rowIndex: this.activeRowIndex, isValid: true })
        }
      },

      getSolutionInputIndex (row) {
        const center = Math.round(this.gridSettings.cellsPerRow / 2) - 1
        return center - row.offset
      },

      // focusInput () {
      //   if (this.activeInputIndex === null || this.activeRowIndex === null) {
      //     return
      //   }
      //
      //   this.$refs.input.focus({ preventScroll: true })
      //
      //   this.$nextTick(() => {
      //     if (isInViewport(this.$el)) {
      //       return
      //     }
      //
      //     this.$SmoothScroll(this.$el, 500)
      //   })
      // },

      setActiveNumberConnector () {
        const connectedCells = this.connectedCells
        const activeRowIndex = this.activeRowIndex
        const activeInputIndex = this.activeInputIndex

        const connectedCellGroup = find(connectedCells, (group) => {
          return find(group.cells, (cell) => {
            return cell.rowIndex === activeRowIndex && cell.inputIndex === activeInputIndex
          })
        })

        const numberConnector = connectedCellGroup ? connectedCellGroup.numberConnector : null
        this.$store.commit('setActiveNumberConnector', { numberConnector })
      }
    },

    watch: {
      activeCell (newValue) {
        this.setActiveNumberConnector()
        // Focusing input has to happen inside the onClick handler to make keyboard appear on iOS
        // this.focusInput()
      }
    },

    computed: {
      ...mapState({
        puzzle: state => state.currentPuzzle,
        gridSettings: state => state.gridSettings,
        activeRowIndex: state => state.gridSettings.activeRowIndex,
        activeInputIndex: state => state.gridSettings.activeInputIndex,
        activeNumberConnector: state => state.gridSettings.activeNumberConnector,
        connectedCells: state => state.gridSettings.connectedCells,
        answers: state => state.answers,
        mobileOperatingSystem: state => state.mobileOperatingSystem
      }),

      activeCell () {
        return { rowIndex: this.activeRowIndex, inputIndex: this.activeInputIndex }
      },

      inputValue () {
        return this.answers[this.activeRowIndex][this.activeInputIndex]
      },

      activeConnectedCells () {
        const activeNumberConnector = this.activeNumberConnector

        if (!activeNumberConnector) {
            return []
        }

        const cellGroup = find(this.connectedCells, (item) => {
          return item.numberConnector && item.numberConnector.id === activeNumberConnector.id
        })

        if (!cellGroup) {
          return []
        }

        return cellGroup.cells
      },

      widthPercentage () {
        const totalCellsPerRow = this.gridSettings.cellsPerRow + 1
        return 100 / totalCellsPerRow
      },

      heightPercentage () {
        return 100 / this.puzzle.rows.length
      },

      activeRow () {
        const activeRowIndex = this.activeRowIndex

        if (activeRowIndex === null) {
          return null
        }

        return this.puzzle.rows[activeRowIndex]
      },

      style () {
        const widthPercentage = this.widthPercentage
        const heightPercentage = this.heightPercentage

        let style = {
          width: `${widthPercentage}%`,
          height: `${heightPercentage}%`,
          top: 0,
          left: 0
        }

        const activeRow = this.activeRow
        const activeInputIndex = this.activeInputIndex

        if (activeRow === null || activeInputIndex === null) {
          return style
        }

        const topPercentage = this.activeRowIndex * heightPercentage
        const leftPercentage = (activeInputIndex + activeRow.offset + 1) * widthPercentage

        style.top = `${topPercentage}%`
        style.left = `${leftPercentage}%`

        return style
      },

      isAdminPuzzleForm () {
        return ['AdminPuzzleFormEdit', 'AdminPuzzleFormCreate'].indexOf(this.$route.name) !== -1
      }
    }
  }
</script>
