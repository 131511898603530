<template>
  <div v-if="puzzleIsInitiated">
    <div class="u-margin-bottom">
<!--      <h2>-->
<!--        <span v-if="id">Puzzel bewerken</span>-->
<!--        <span v-else>Puzzel aanmaken</span>-->
<!--      </h2>-->

      <Notification v-if="form.error" :notification="form.error" status="error"/>

       <div class="u-margin-bottom">
         <FieldInput
                 :fieldId="formFields.title.name"
                 :label="formFields.title.label"
                 :value="formFields.title.value"
                 :required="formFields.title.required"
                 :name="formFields.title.name"
                 :placeholder="formFields.title.placeholder"
                 :type="formFields.title.type"
                 :rule="formFields.title.rule"
                 :module="module"
                 :subform="subform"
                 :error="formFields.title.error"
                 :formError="form.error"
         />

           <div class="o-flex">
               <FieldDate
                    class="u-margin-right-tiny"
                     :fieldId="formFields.start_date.name"
                     :label="formFields.start_date.label"
                     :value="formFields.start_date.value"
                     :required="formFields.start_date.required"
                     :name="formFields.start_date.name"
                     :placeholder="formFields.start_date.placeholder"
                     :rule="formFields.start_date.rule"
                     :module="module"
                     :subform="subform"
                     :error="formFields.start_date.error"
                     :formError="form.error"
                     mask=""
             />

               <FieldTime
                       :fieldId="formFields.start_hour.name"
                       :label="formFields.start_hour.label"
                       :value="formFields.start_hour.value"
                       :required="formFields.start_hour.required"
                       :name="formFields.start_hour.name"
                       :placeholder="formFields.start_hour.placeholder"
                       :rule="formFields.start_hour.rule"
                       :module="module"
                       :subform="subform"
                       :error="formFields.start_hour.error"
                       :formError="form.error"
               />
           </div>

         <FieldCheckbox
                 :fieldId="formFields.published.name"
                 :label="formFields.published.label"
                 :value="formFields.published.value"
                 :required="formFields.published.required"
                 :name="formFields.published.name"
                 :placeholder="formFields.published.placeholder"
                 :rule="formFields.published.rule"
                 :module="module"
                 :subform="subform"
                 :error="formFields.published.error"
                 :formError="form.error"
         />
       </div>
    </div>

    <div>
      <div class="o-flex o-flex--justify-space-between o-flex--align-center">

        <FieldCheckbox
                :fieldId="formFields.show_solution.name"
                :label="formFields.show_solution.label"
                :value="formFields.show_solution.value"
                :required="formFields.show_solution.required"
                :name="formFields.show_solution.name"
                :placeholder="formFields.show_solution.placeholder"
                :rule="formFields.show_solution.rule"
                :module="module"
                :subform="subform"
                :error="formFields.show_solution.error"
                :formError="form.error"
                @change="toggleSolution"
        />
      </div>

      <div class="o-layout u-margin-top">
        <div class="o-layout__item u-1/2@tablet">
          <div class="c-grid__wrapper u-margin-bottom u-padding-bottom u-padding-right-small@tablet">
            <PuzzleHint/>
            <PuzzleGrid/>
            <PuzzleHint/>
          </div>
        </div>

        <PuzzleHintList :showAdminForm="true" class="o-layout__item u-1/2@tablet"/>
      </div>

      <div class="c-solution u-margin-bottom">
        <h2>Oplossing</h2>

        <PuzzleSolutionGrid class="u-margin-bottom"/>
      </div>

      <AdminPuzzleNumberConnectorsForm/>
    </div>

    <button class="c-button c-button--ghost u-margin-top-small" @click="handleSubmit">
      Opslaan
    </button>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import moment from 'moment'
  import { filter } from 'lodash'
  import { omitDeep } from '@/utils/vuexUtils'

  import {
    GET_PUZZLE_BY_ID,
    DELETE_NUMBERS,
    DELETE_ROWS,
    DELETE_LEGENDS,
    CREATE_PUZZLE,
    UPDATE_PUZZLE
  } from '@/utils/graphql'

  import PuzzleGrid from '@/components/puzzle/PuzzleGrid'
  import PuzzleHint from '@/components/puzzle/PuzzleHint'
  import PuzzleHintList from '@/components/puzzle/PuzzleHintList'
  import PuzzleSolutionGrid from '@/components/puzzle/PuzzleSolutionGrid'
  import Notification from '@/components/Notification'
  import FieldInput from '@/components/forms/FieldInput'
  import FieldCheckbox from '@/components/forms/FieldCheckbox'
  import FieldDate from '@/components/forms/FieldDate'
  import FieldTime from '@/components/forms/FieldTime'
  import AdminPuzzleNumberConnectorsForm from '@/modules/admin/_components/AdminPuzzleNumberConnectorsForm'

  const PUBLICATION_SETTINGS = window.PUBLICATION_SETTINGS

  export default {
    name: 'AdminPuzzleForm',

    data () {
      return {
        module: 'admin',
        subform: 'puzzleForm',
        puzzleIsInitiated: false
      }
    },

    created () {
      if (!this.id) {
        this.setDefaultDate()
      }
    },

    methods: {
      async handleSubmit () {
        this.setFormError(null)
        const module = this.module
        const subform = this.subform
        const form = this.form

        this.$store.dispatch('forms/validateForm', { module, subform })

        if (!form.isValid) {
          this.$SmoothScroll(this.$el, 500)
          return
        }

        const formFields = form.fields

        let variables = omitDeep(this.currentPuzzle, ['__typename'])
        variables.title = formFields.title.value
        // variables.start_date = moment(formFields.start_date.value, moment.ISO_8601).format('YYYY-MM-DD')

        const startDate = moment(formFields.start_date.value, moment.ISO_8601)
        const startHourParts = formFields.start_hour.value ? formFields.start_hour.value.split(':') : []
        if (startHourParts.length === 2) {
          startDate.set('hours', startHourParts[0])
          startDate.set('minutes', startHourParts[1])
        }
        variables.start_date = startDate.format('YYYY-MM-DD HH:mm:ss')

        variables.published = Boolean(formFields.published.value)
        variables.solution = this.puzzleSolution
        // console.log(variables)

        variables.legends = filter(variables.legends, (item) => {
          const id = item.id
          if (id && item.number === '') {
            this.$store.commit('admin/addIdToDelete', { type: 'legend', id: parseInt(id) })
          }

          return item.number !== ''
        })

        let mutation = CREATE_PUZZLE

        if (this.id) {
          this.deletePuzzleObjects()
          mutation = UPDATE_PUZZLE
        }

        this.$apollo.mutate({
          mutation,
          variables,
          client: 'auth'
        }).then((data) => {
          // Result
          const notification = {
            message: '✓ De puzzel is succesvol opgeslagen',
            status: 'info'
          }
          this.$store.dispatch('setSnackar', { module, notification })
          this.$router.push({ name: 'AdminDashboard' })
        }).catch((error) => {
          console.log(error)
          this.setFormError('Er liep iets mis bij het verwerken van de data')
          this.$SmoothScroll(this.$el, 500)
        })
      },

      deletePuzzleObjects () {
        const idsToDelete = this.idsToDelete
        let success = true

        const mapping = [
          {
            type: 'number',
            mutation: DELETE_NUMBERS
          },
          {
            type: 'row',
            mutation: DELETE_ROWS
          },
          {
            type: 'legend',
            mutation: DELETE_LEGENDS
          }
        ]

        mapping.forEach(async (item) => {
          if (!idsToDelete[item.type].length) {
            return
          }

          const variables = {}
          variables[`${item.type}_ids`] = idsToDelete[item.type].join(',')

          await this.$apollo.mutate({
            mutation: item.mutation,
            variables,
            client: 'auth'
          })
            .catch((error) => {
              console.log(error)
              success = false
            })
        })

        if (success) {
          this.$store.commit('admin/resetIdsToDelete')
        }

        return success
      },

      setFormError (error) {
        const module = this.module
        const subform = this.subform
        this.$store.commit('setFormError', { module, subform, error })
      },

      setFieldValues (data) {
        const mapping = {
          title: data.title,
          start_date: data.start_date,
          published: data.published
        }

        if (mapping.start_date) {
          const startDate = moment(mapping.start_date)
          mapping.start_hour = startDate.format('HH:mm')
        }

        Object.keys(mapping).forEach((field) => {
          const value = mapping[field]
          this.$store.commit('setFieldValue', {
            module: this.module,
            subform: this.subform,
            field,
            value
          })
        })

        data.legends.forEach((item) => {
          this.$store.commit('setFieldValue', {
            module: this.module,
            subform: 'puzzleNumberConnectorsForm',
            field: item.letter,
            value: item.number.toString()
          })
        })

        this.$store.dispatch('initiatePuzzle', { puzzle: data })
          .then(() => {
            this.puzzleIsInitiated = true

            if (!data.rows.length) {
              this.addDummyPuzzleRow()
            }
          })
      },

      setDefaultDate () {
        // Set default date
        const fieldStartDate = this.formFields.start_date
        if (!fieldStartDate.value) {
          this.$store.commit('setFieldValue', {
            module: this.module,
            subform: this.subform,
            field: 'start_date',
            value: new Date()
          })
        }
        const fieldStartHour = this.formFields.start_hour
        if (!fieldStartHour.value) {
          this.$store.commit('setFieldValue', {
            module: this.module,
            subform: this.subform,
            field: 'start_hour',
            value: PUBLICATION_SETTINGS.start_hour
          })
        }
      },

      createNewPuzzle () {
        const puzzle = {
          rows: [],
          legends: []
        }

        this.$store.dispatch('initiatePuzzle', { puzzle })
          .then(() => {
            this.puzzleIsInitiated = true

            this.addDummyPuzzleRow()
          })
      },

      addDummyPuzzleRow () {
        this.$store.dispatch('addPuzzleRow', { dummyData: true })
      },

      resetPuzzleForm () {
        this.$store.commit('admin/resetPuzzleForm')
        this.$store.commit('admin/resetFields', { subform: 'puzzleNumberConnectorsForm' })
        this.$store.commit('resetFormError', { module: this.module, subform: 'puzzleNumberConnectorsForm' })
        this.$store.commit('admin/resetIdsToDelete')
      },

      toggleSolution (show) {
        if (!show) {
          this.$store.commit('initiateAnswers', { puzzle: this.currentPuzzle })
        }

        this.$store.commit('setShowSolution', { show })
      }
    },

    apollo: {
      puzzle: {
        query () {
          return GET_PUZZLE_BY_ID
        },
        variables () {
          return {
            id: parseInt(this.id)
          }
        },
        // Variables: deep object watch
        deep: false,
        fetchPolicy: 'no-cache',
        // We use a custom update callback because
        // the field names don't match
        update: data => data.puzzle,

        result (result, key) {
          const data = result.data.puzzle
          if (!data) {
            return
          }

          this.setFieldValues(data)
        },
        skip () {
          // Only run when updating Text
          return !this.id
        }
      }
    },

    watch: {
      '$route': {
        handler: function () {
          this.resetPuzzleForm()
          this.puzzleIsInitiated = false

          this.$store.commit('setShowSolution', { show: true })

          if (!this.id) {
            this.createNewPuzzle()
          }
        },
        immediate: true
      }
    },

    computed: {
      ...mapGetters([
        'puzzleSolution'
      ]),

      ...mapState({
        currentPuzzle: state => state.currentPuzzle
      }),

      ...mapState('admin', {
        form: state => state.puzzleForm.form,
        formFields: state => state.puzzleForm.form.fields,
        idsToDelete: state => state.idsToDelete
      }),

      id () {
        return this.$route.params.id
      }
    },

    components: {
      PuzzleGrid,
      PuzzleHint,
      PuzzleHintList,
      PuzzleSolutionGrid,
      Notification,
      FieldInput,
      FieldCheckbox,
      FieldDate,
      FieldTime,
      AdminPuzzleNumberConnectorsForm
    }
  }
</script>
