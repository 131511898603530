<template>
    <div class="o-wrapper o-wrapper--max-width u-margin-top u-margin-bottom u-margin-bottom-large@tablet">
        <div class="o-layout">
            <div class="o-layout__item u-2/3@desktop u-push-1/6@desktop">
                <div class="u-margin-bottom">
                    <h1>Karels Crypto</h1>
                </div>

                <form @submit.prevent="handleSubmit" class="u-border u-padding">
                    <h2>Aanmelden</h2>

                    <Notification v-if="form.error" :notification="form.error" status="error"/>

                    <div class="o-layout">
                        <div class="o-layout__item u-1/2">
                            <FieldInput
                                    :fieldId="formFields.email.name"
                                    :label="formFields.email.label"
                                    :value="formFields.email.value"
                                    :required="formFields.email.required"
                                    :name="formFields.email.name"
                                    :placeholder="formFields.email.placeholder"
                                    :type="formFields.email.type"
                                    :rule="formFields.email.rule"
                                    :module="module"
                                    :subform="subform"
                                    :error="formFields.email.error"
                                    :formError="form.error"
                            />

                        </div>
                        <div class="o-layout__item u-1/2">
                            <FieldInput
                                    :fieldId="formFields.password.name"
                                    :label="formFields.password.label"
                                    :value="formFields.password.value"
                                    :required="formFields.password.required"
                                    :name="formFields.password.name"
                                    :placeholder="formFields.password.placeholder"
                                    :type="formFields.password.type"
                                    :rule="formFields.password.rule"
                                    :module="module"
                                    :subform="subform"
                                    :error="formFields.password.error"
                                    :formError="form.error"
                            />
                        </div>
                    </div>

                    <button class="c-button c-button--ghost" type="submit">
                        Verzenden
                    </button>
                </form>

            </div>
        </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import {
    LOGIN
  } from '@/utils/graphql'

  import FieldInput from '@/components/forms/FieldInput'
  import Notification from '@/components/Notification'

  export default {
    name: 'AdminLogin',

    data () {
      return {
        module: 'admin',
        subform: 'login',
        skipUserQuery: true
      }
    },

    created () {
      if (this.isAuthenticated) {
        this.$router.push({ name: 'AdminDashboard' })
      }
    },

    methods: {
      handleSubmit () {
        this.setFormError(null)
        const module = this.module
        const subform = this.subform
        const form = this.form

        this.$store.dispatch('forms/validateForm', { module, subform })

        if (!form.isValid) {
          return
        }

        this.skipUserQuery = false
        this.$apollo.queries.user.refetch()
      },

      authenticateUser (user) {
        this.$store.commit('admin/setAuthentication', { user })
        this.$router.push({ name: 'AdminDashboard' })
      },

      setFormError (error) {
        const module = this.module
        const subform = this.subform
        this.$store.commit('setFormError', { module, subform, error })
      }
    },

    computed: {
      ...mapState('admin', {
        isAuthenticated: state => state.currentUser.isAuthenticated,
        form: state => state.login.form,
        formFields: state => state.login.form.fields
      })
    },

    apollo: {
      user: {
        query: LOGIN,
        variables () {
          return {
            email: this.form.fields.email.value,
            password: this.form.fields.password.value
          }
        },
        update: data => data.token,
        result (result) {
          this.skipUserQuery = true

          if (result.errors) {
            this.setFormError('Incorrecte combinatie')
            return
          }

          const user = result.data.login

          if (user.token) {
            this.authenticateUser(user)
          } else {
            this.setFormError('Incorrecte combinatie')
          }
        },
        skip () {
          return this.skipUserQuery
        }
      }
    },

    components: {
      FieldInput,
      Notification
    }
  }
</script>
