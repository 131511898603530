<template>
    <svg :viewBox="`0 0 ${viewBoxWidth} ${viewBoxHeight}`"
         preserveAspectRatio="xMinYMin meet"
         class="c-grid__svg"
         :style="`max-height: ${maxCellSize}x; width: ${maxCellSize * puzzle.rows.length}px; max-width: 100%;`"
    >

        <PuzzleSolutionCell
            v-for="(item, index) in puzzle.rows"
            :index="index"
            :key="item.id"
            :row="item"
        />
    </svg>
</template>

<script>
  import { mapState } from 'vuex'

  import PuzzleSolutionCell from '@/components/puzzle/PuzzleSolutionCell'

  export default {
    name: 'PuzzleSolutionGrid',

    data () {
      return {
        maxCellSize: 50
      }
    },

    methods: {
    },

    computed: {
      ...mapState({
        puzzle: state => state.currentPuzzle,
        gridSettings: state => state.gridSettings
      }),

      viewBoxWidth () {
        return this.gridSettings.cellSize * this.puzzle.rows.length
      },

      viewBoxHeight () {
        return this.gridSettings.cellSize
      }
    },

    components: {
      PuzzleSolutionCell
    }
  }
</script>
