<template>
    <div>
        <h2>Teksten</h2>

        <table class="c-table" v-if="texts.length">
            <tr>
                <th class="c-table__column c-table__column--head">
                    Titel
                </th>
                <th class="c-table__column c-table__column--head">

                </th>
            </tr>
            <router-link v-for="item in texts"
                 :to="{ name: 'AdminTextFormEdit', params: { id: item.id } }"
                :key="item.id"
                 tag="tr"
                class="c-table__row c-table__row--link"
            >
                <td class="c-table__column">
                    {{ item.title }}
                </td>
                <td class="c-table__column u-align-right">
                    ✎ Bewerk
                </td>
            </router-link>
        </table>

        <router-link :to="{ name: 'AdminTextFormCreate' }" class="c-button c-button--ghost u-margin-bottom-tiny">
            + Nieuwe tekst
        </router-link>
    </div>
</template>

<script>
  import { mapState } from 'vuex'

  import {
    GET_TEXTS
  } from '@/utils/graphql'

  export default {
    name: 'AdminTextList',

    apollo: {
      texts: {
        query () {
          return GET_TEXTS
        },
        deep: false,
        fetchPolicy: 'no-cache',
        result (result, key) {
          this.$store.commit('setTexts', { texts: result.data.texts })
        }
      }
    },

    computed: {
      ...mapState({
        texts: state => state.texts
      })
    }
  }
</script>
