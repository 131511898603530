const getValues = (state, getters, rootState) => ({ module, subform, valueKey }) => {
  const data = {}

  let fields
  if (subform) fields = rootState[module][subform].form.fields
  else fields = rootState[module].form.fields

  Object.keys(fields).map(key => {
    if (valueKey) {
      data[key] = fields[key][valueKey]
    } else {
      data[key] = fields[key].value
    }
  })

  return data
}

const getFieldRule = (state, getters, rootState) => ({ module, subform, field }) => {
  if (subform) return rootState[module][subform].form.fields[field].rule
  else return rootState[module].form.fields[field].rule
}

const getValueObject = (state) => ({ value, options }) => {
  return options.find(option => option.value === value)
}

export default {
  getValues,
  getFieldRule,
  getValueObject
}
