<template>
    <div v-if="puzzle">
        <div class="o-wrapper u-padding-none">
            <div class="o-layout o-layout--puzzle">
                <div class="o-layout__item u-2/3@tablet u-position-relative" ref="grid">
                    <div class="u-margin-bottom@tablet u-padding-bottom u-padding-vertical-none@tablet u-padding-right-small@tablet">
                        <button class="c-button c-button--scroll c-button--scroll--top-right u-hidden-from@tablet" @click="$SmoothScroll($refs.hint_list, 500)">
                            <span class="u-margin-right-small">Alle opgaven</span> <IconArrowSquare direction="down"/>
                        </button>

                        <PuzzleHint/>
                        <PuzzleGrid/>
                        <PuzzleHint/>
                        <PuzzleActions v-if="!showSolution" class="u-margin-top@tablet"/>
                    </div>
                </div>

                <div class="o-layout__item u-1/3@tablet o-layout__item--hint-list u-position-relative" ref="hint_list">
                    <button class="c-button c-button--scroll c-button--scroll--top-right u-hidden-from@tablet" @click="$SmoothScroll($refs.grid, 500)">
                        <span class="u-margin-right-small">Puzzel</span> <IconArrowSquare direction="up"/>
                    </button>

                    <PuzzleHintList class="u-margin-top u-margin-top-none@tablet" />
                </div>

                <div class="o-layout__item u-margin-bottom u-margin-bottom-none@tablet">
                    <div class="o-wrapper u-bg-primary u-bg-none@tablet">
                        <PuzzleSolution class="u-margin-vertical u-margin-top-none@tablet"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapState } from 'vuex'

  import IconArrowSquare from '@/components/icons/IconArrowSquare'
  import PuzzleGrid from '@/components/puzzle/PuzzleGrid'
  import PuzzleHint from '@/components/puzzle/PuzzleHint'
  import PuzzleActions from '@/components/puzzle/PuzzleActions'
  import PuzzleHintList from '@/components/puzzle/PuzzleHintList'
  import PuzzleSolution from '@/components/puzzle/PuzzleSolution'

  export default {
    name: 'Puzzle',

    watch: {
      showSolution: function (newValue) {
        if (!newValue) {
          return
        }

        this.$SmoothScroll(this.$refs.grid, 500)
      }
    },

    computed: {
      ...mapState({
        puzzle: state => state.currentPuzzle,
        gridSettings: state => state.gridSettings,
        showSolution: state => state.showSolution
      })
    },

    components: {
      IconArrowSquare,
      PuzzleGrid,
      PuzzleHint,
      PuzzleActions,
      PuzzleHintList,
      PuzzleSolution
    }
  }
</script>
