import { includes } from 'lodash'
import VueHead from 'vue-head'
import moment from 'moment'
// import VueAnalytics from 'vue-analytics'
import { Plugin } from 'vue-fragment'
import VueApollo from 'vue-apollo'
import ApolloClient from 'apollo-boost'
import vueSmoothScroll from 'vue-smoothscroll'
import VueMask from 'v-mask'

import Vue from 'vue'
import App from './App.vue'
import router from './router'

import store from './store'
import adminStore from '@/modules/admin/_store'

import getMobileOperatingSystem from '@/utils/getMobileOperatingSystem'

store.registerModule('admin', adminStore)

Vue.config.productionTip = false

/*
 *  VUE FILTERS
 *  ============
 */
Vue.filter('dynamic',
    function (text) {
        /*
        * Replaces certain content with dynamic state content
        * usage: {{ stateString | dynamic }}
        * $options.filters.dynamic(intro.content)
        */
        const regex = /{{([^}]+)}}/g

        let dynamicText = text
        if (regex.test(text) || includes(text, '<a href="/')) {
            // Replace links to router links

            dynamicText = dynamicText.replace('<a href="/', '<router-link to="/')
            dynamicText = dynamicText.replace('</a>', '</router-link>')

            return dynamicText
        } else {
            return text
        }
    }
)

Vue.filter('capitalize',
  function (text) {
    if (text && text !== '') {
      return text.charAt(0).toUpperCase() + text.slice(1)
    }
    return text
  }
)

Vue.filter('uppercase',
  function (text) {
    return text.toUpperCase()
  }
)

Vue.filter('lowercase',
  function (text) {
    return text.toLowerCase()
  }
)

Vue.filter('date',
  function (value, format = 'DD/MM/YYYY') {
    if (value !== '') {
      return moment(value).locale('nl').format(format)
    } else {
      return ''
    }
  }
)

Vue.filter('numberToLetter',
  function (value, toUpperCase = true) {
    const alphabetStart = toUpperCase ? 65 : 97
    return String.fromCharCode(alphabetStart + value)
  }
)

const graphQlBaseUrl = `${process.env.VUE_APP_URL}/graphql`

const apolloClientPublic = new ApolloClient({
  uri: graphQlBaseUrl
})

const apolloClientAuth = new ApolloClient({
  uri: `${graphQlBaseUrl}/auth`,
  request: (operation) => {
    const token = localStorage.getItem('token')
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    })
  }
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClientPublic,
  clients: {
    public: apolloClientPublic,
    auth: apolloClientAuth
  }
})

Vue.use(VueApollo)

Vue.use(VueMask)

/*
 *  VUE TRANSLATIONS
 *  ============
 */
const vueApp = new Vue({
    el: '#app',
    router,
    store,
    apolloProvider,
    template: '<App/>',
    components: { App },
    created () {
      const mobileOperatingSystem = getMobileOperatingSystem()
      this.$store.commit('setMobileOperatingSystem', { mobileOperatingSystem })
    }
})

Vue.use(vueApp)

/*
 *  VUE ANALYTICS
 *  ============
 */
// Vue.use(VueAnalytics, {
//     id: 'UA-XXX-XXXX',
//     checkDuplicatedScript: true,
//     router
// })

/*
 *  VUE SEO DATA
 *  ============
 */
Vue.use(VueHead, {
    separator: '-',
    complement: 'Karels Crypto'
})

// import VueMq from 'vue-mq'
//
// Vue.use(VueMq, {
//   breakpoints: {
//     mobile: 480,
//     tablet: 768,
//     desktop: 1024,
//     wide: Infinity
//   },
//   defaultBreakpoint: 'mobile'
// })

/*
 *  Fragments
 */
Vue.use(Plugin)

/*
 *  VUE SMOOTH SCROLL
 */
Vue.use(vueSmoothScroll)
