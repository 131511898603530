var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{staticClass:"u-margin-none u-height"},[_c('div',{staticClass:"c-form__time-wrapper"},[(_vm.label)?_c('label',{class:{
          'c-form__label': true,
          'u-error': _vm.formError && _vm.error
        },attrs:{"for":_vm.inputId}},[_vm._v("\n      "+_vm._s(_vm._f("capitalize")(_vm.label))+" "),(!_vm.required)?_c('em',[_vm._v("("+_vm._s(_vm.optional)+")")]):_vm._e()]):_vm._e(),_c('div',{class:{
            'u-height': true,
            'c-form__time-error': _vm.formErrors && _vm.error && _vm.errorIndex > -1,
          }},[(!_vm.readonly && !_vm.disabled)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.timeValue),expression:"timeValue"},{name:"mask",rawName:"v-mask",value:('#?#:##'),expression:"'#?#:##'"}],class:{
            'c-form__input': true,
            'first': true,
            'c-form__time': true
          },attrs:{"id":_vm.inputId,"type":"text","name":_vm.name,"placeholder":"08:00","required":_vm.required,"autofocus":_vm.autofocus === 'true' || _vm.autofocus === true ? 'autofocus' : false},domProps:{"value":(_vm.timeValue)},on:{"focusin":_vm.handleFocus,"input":[function($event){if($event.target.composing){ return; }_vm.timeValue=$event.target.value},_vm.handleChange],"focusout":function (e) { _vm.handleUnfocus(); _vm.handleChange(e); }}}):_c('input',{class:{
            'c-form__input': true,
            'c-disabled': true,
            'c-form__time': true
          },attrs:{"id":_vm.inputId,"type":"text","name":_vm.name,"placeholder":"08:00","required":_vm.required,"autofocus":_vm.autofocus === 'true' || _vm.autofocus === true ? 'autofocus' : false,"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"focusin":_vm.handleFocus,"focusout":function (e) { _vm.handleUnfocus(); }}})]),(_vm.formError && _vm.error)?_c('p',{staticClass:"c-form__error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }