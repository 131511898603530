<template>
    <PuzzleCell
        :class="{
            'c-grid__cell--solution': true,
            'c-grid__cell--active': isActive
        }"
        :xIndex="index"
        :yIndex="0"
        :text="text ? text.toUpperCase() : ''"
    />
</template>

<script>
  import { mapState } from 'vuex'
  import { Fragment } from 'vue-fragment' /* Special element ignored by DOM */

  import PuzzleCell from '@/components/puzzle/PuzzleCell'

  export default {
    name: 'PuzzleSolutionCell',

    props: ['row', 'index'],

    computed: {
      ...mapState({
        puzzle: state => state.currentPuzzle,
        answers: state => state.answers,
        showSolution: state => state.showSolution,
        gridSettings: state => state.gridSettings,
        activeNumberConnector: state => state.gridSettings.activeNumberConnector,
        size: state => state.gridSettings.cellSize
      }),

      text () {
        return this.showSolution ? this.solutionValue : this.inputValue
      },

      solutionValue () {
        return this.puzzle.rows[this.index].answer[this.solutionIndex]
      },

      inputValue () {
        return this.answers[this.index][this.solutionIndex]
      },

      solutionIndex () {
        const center = Math.round(this.gridSettings.cellsPerRow / 2) - 1
        const offset = this.row.offset

        return center - offset
      },

      isHighlighted () {
        return this.gridSettings.activeRowIndex === 'solution'
      },

      isActive () {
        return this.isHighlighted && this.gridSettings.activeInputIndex === this.index
      }
    },

    components: {
      Fragment,
      PuzzleCell
    }
  }
</script>
