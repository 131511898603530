<template>
    <div></div>
</template>

<script>
  export default {
    name: 'AdminLogout',

    created () {
      this.$store.commit('admin/removeAuthentication')
      this.$router.push({ name: 'AdminLogin' })
    }
  }
</script>
