<template>

  <div class="c-form__group" :class="{ 'is-invalid': formError && error }">

    <label
      v-if="label"
      :for="fieldId"
      class="c-form__label">
      {{ label }}
    </label>

    <div class="c-form__input__wrapper">
      <input
        :class="{
          'c-form__input': true,
          'c-form__input--error': formError && error,
          'c-form__input--warning': warning
        }"
        :id="fieldId"
        :type="type"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        :autofocus="autofocus === 'true' || autofocus === true ? 'autofocus' : false"
        v-on:input="handleChange"
        :step="step ? step : 1"
        :maxlength="maxlength ? maxlength : ''"
        :minlength="minlength ? minlength : ''"
        :max="max"
        :min="min"
        :disabled="disabled === 'true' || disabled === true ? true : false"
        :readonly="readonly === 'true' || readonly === true ? true : false"  />
    </div>

    <p v-if="helper" class="u-margin-top-tiny u-margin-bottom-none">
      <component :is="$options.filters.dynamic(`<div>${helper}</div>`) && {template: $options.filters.dynamic(`<div>${helper}</div>`)}" />
    </p>

    <p v-if="formError && error" class='c-form__error'>{{ error }}</p>

  </div>

</template>

<script>

export default {
  name: 'FieldInput',

  props: [
    'module',
    'subform',
    'fieldId',

    'type',
    'label',
    'placeholder',

    'name',
    'value',
    'error',
    'warning',
    'autofocus',
    'disabled',
    'required',
    'min', // numbers
    'max', // numbers
    'maxlength',
    'minlength',
    'step',
    'readonly',

    'helper',
    'formError'
  ],

  mounted () {
    /*
    *   Validate Field, to show checkmark
    */
    if (!this.readonly && !this.disabled) {
      this.$store.dispatch('forms/validateField', {
        module: this.module,
        subform: this.subform,
        field: this.name,
        value: this.value
      })
    }
  },

  methods: {
    handleChange (e) {
      /*
      *   Set Value
      */
      let value = e.target.value

      this.setValue(value)
    },

    setValue (value) {
      this.$store.commit('setFieldValue', {
        module: this.module,
        subform: this.subform,
        field: this.name,
        value: value
      })

      /*
      *   Validate Field
      */
      this.$store.dispatch('forms/validateField', {
        module: this.module,
        subform: this.subform,
        field: this.name,
        value: value
      })

      this.$emit('change', value)
    }
  },

  components: {
  }
}
</script>
