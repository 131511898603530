import includes from 'lodash/includes'
import find from 'lodash/find'

/*
* FIELD BASED MUTATIONS
*/

/* set field value */
export const setFieldValue = (state, { module, subform, field, value }) => {
  if (subform) state[module][subform].form.fields[field].value = value
  else state[module].form.fields[field].value = value
}

// regular:
export const addOption = (state, { module, subform, field, value }) => {
    if (subform) state[module][subform].form.fields[field].value.push(value)
    else state[module].form.fields[field].value.push(value)
}

export const addField = (state, { module, subform, field }) => {
  const form = subform ? state[module][subform].form : state[module].form
  form.fields[field.name] = field
}

export const removeOption = (state, { module, subform, field, value }) => {
    if (subform) {
        const currentValue = state[module][subform].form.fields[field].value
        const index = currentValue.indexOf(value)
        currentValue.splice(index, 1)
    } else {
        const currentValue = state[module].form.fields[field].value
        const index = currentValue.indexOf(value)
        currentValue.splice(index, 1)
    }
}

export const setFieldOptions = (state, { module, subform, field, options }) => {
  if (subform) state[module][subform].form.fields[field].options = options
  else state[module].form.fields[field].options = options
}

/* set field error */
export const setFieldError = (state, { module, subform, field, error, person }) => {
  if (subform) {
    state[module][subform].form.fields[field].error = error
  } else {
    if (person !== undefined) {
      field.error = error
      state[module].people[person].is_valid = false
    } else {
      state[module].form.fields[field].error = error
    }
  }
}

export const setUniqueFieldError = (state, { module, error }) => {
    state[module].form.fields.email.error = error
}

export const resetFieldError = (state, { module, subform, field }) => {
  if (subform) {
    state[module][subform].form.fields[field].error = ''
  } else {
    state[module].form.fields[field].error = ''
  }
}

export const resetFields = (state, { module, subform }) => {
  const form = subform ? state[module][subform].form : state[module].form
  Object.keys(form.fields).map(key => {
    form.fields[key].value = ''
    form.fields[key].error = ''
  })
}

/*
* FORM BASED MUTATIONS
*/
/* set field on valid */
export const setFormValid = (state, { module, subform, isValid }) => {
  if (subform) state[module][subform].form.isValid = isValid
  else state[module].form.isValid = isValid
}

/* set form error */
export const setFormError = (state, { module, subform, error }) => {
  if (subform) state[module][subform].form.error = error
  else state[module].form.error = error
}

export const resetFormError = (state, { module, subform }) => {
  if (subform) {
    state[module][subform].form.error = ''
    state[module][subform].form.errors = []
  } else {
    state[module].form.error = ''
    state[module].form.errors = ''
  }
}

/* Set a form notification */
export const setNotification = (state, { module, subform, isForm, notification }) => {
  if (isForm) {
    if (subform) state[module][subform].form.notification = notification
    else state[module].form.notification = notification
  } else {
    state[module].notification = notification
  }
}

export const resetNotification = (state, { module, subform, isForm }) => {
  if (isForm) {
    if (subform) state[module][subform].form.notification = ''
    else state[module].form.notification = ''
  } else {
    state[module].notification = ''
  }
}

/* set field rule */
export const addFieldRule = (state, { module, subform, field, rule }) => {
  let rules
  if (subform) {
    rules = state[module][subform].form.fields[field].rule

    /* Removing inRule */
    let inRule = find(rules, rule => {
        return includes(rule, 'in:')
    })
    inRule = rules.indexOf(inRule)
    if (inRule > -1) {
        rules.splice(inRule, 1)
    }

    /* Adding new rule */
    rules.push(rule)
    state[module][subform].form.fields[field].rule = rules
  } else {
    rules = state[module].form.fields[field].rule

    /* Removing inRule */
    let inRule = find(rules, rule => {
        return includes(rule, 'in:')
    })

    inRule = rules.indexOf(inRule)
    if (inRule > -1) {
        rules.splice(inRule, 1)
    }

    /* Adding new rule */
    rules.push(rule)
    state[module].form.fields[field].rule = rules
  }
}
