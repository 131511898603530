<template>
    <div class="o-flex o-flex--column o-flex--align-center">
        <div class="u-margin-bottom-small">
            Uw voortgang wordt automatisch opgeslagen
        </div>

<!--        <button v-if="activeRowIndex !== null" class="c-button c-button&#45;&#45;primary u-margin-bottom-small" @click="clearRow">-->
<!--            Wis rij-->
<!--        </button>-->

        <button class="c-button c-button--ghost" @click="toggleClearAllPopup">
            Wis alles
        </button>

        <Popup v-if="showPopup && popupType === popupTypeClearAll">
            <div class="o-flex o-flex--column o-flex--align-center">
                <p>
                    Bent u zeker dat u alles wil wissen?
                </p>

                <button class="c-button c-button--primary u-margin-bottom-small" @click="clearAnswers">
                    Wis alles
                </button>

                <button class="c-button c-button--ghost u-margin-bottom-small" @click="toggleClearAllPopup">
                    Annuleren
                </button>
            </div>
        </Popup>
    </div>
</template>

<script>
  import { mapState } from 'vuex'

  import Popup from '@/components/Popup'

  export default {
    name: 'PuzzleActions',

    data () {
      return {
        popupTypeClearAll: 'clear_all'
      }
    },

    methods: {
      clearRow () {
        this.$store.dispatch('clearAnswerRow', { rowIndex: this.activeRowIndex })
      },
      clearAnswers () {
        this.$store.dispatch('clearAnswers')
        this.$store.commit('resetPopup')
      },
      toggleClearAllPopup () {
        this.togglePopup(this.popupTypeClearAll)
      },
      togglePopup (type) {
        this.$store.commit('togglePopup', { type })
      }
    },

    computed: {
      ...mapState({
        activeRowIndex: state => state.gridSettings.activeRowIndex,
        showPopup: state => state.showPopup,
        popupType: state => state.popupType
      })
    },

    components: {
      Popup
    }
  }
</script>
