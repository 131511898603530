import gql from 'graphql-tag'

// PUZZLES

export const puzzleListProperties = `
    id
    title
    start_date
    published
`

export const puzzleDetailProperties = `
    id
    title
    start_date
    solution
    published
    rows {
        id
        offset
        hint
        answer
        index
        numbers {
            id
            index
        }
    }
    legends {
        id
        number
        letter
    }
`

export const GET_PUZZLES = gql`
    query getPuzzles($limit: Int, $page: Int, $published: Boolean) {
        puzzles(limit: $limit, page: $page, published: $published) {
            data {
                ${puzzleListProperties}
            }
            total
        }
    }
`

export const GET_PUZZLE_BY_ID = gql`
    query getPuzzleById($id: ID!) {
        puzzle(id: $id) {
            ${puzzleDetailProperties}
        }
    }
`

export const GET_HOME_PUZZLE = gql`
    query getHomePuzzle {
        published_puzzle {
            ${puzzleDetailProperties}
        }
    }
`

const savePuzzleParams = `
    $title: String!,
    $solution: String!,
    $start_date: String!,
    $published: Boolean!,
    $rows: [RowInput]
    $legends: [LegendInput]
`

const savePuzzlePostData = `
    title: $title,
    solution: $solution,
    start_date: $start_date,
    published: $published,
    rows: $rows
    legends: $legends
`

export const CREATE_PUZZLE = gql`
    mutation createPuzzle(
        ${savePuzzleParams}

    ) {
        CreatePuzzle(
            ${savePuzzlePostData}
        ) {
            ${puzzleDetailProperties}
        }
    }
`

export const UPDATE_PUZZLE = gql`
    mutation updatePuzzle(
        $id: ID!,
        ${savePuzzleParams}

    ) {
        UpdatePuzzle(
            id: $id,
            ${savePuzzlePostData}
        ) {
            ${puzzleDetailProperties}
        }
    }
`

export const DELETE_NUMBERS = gql`
    mutation deleteNumbers(
        $number_ids: String!
    ) {
        DeleteNumbers(
            number_ids: $number_ids
        ) {
            id
        }
    }
`

export const DELETE_ROWS = gql`
    mutation deleteRows(
        $row_ids: String!
    ) {
        DeleteRows(
            row_ids: $row_ids
        ) {
            id
        }
    }
`

export const DELETE_LEGENDS = gql`
    mutation deleteLegends(
        $legend_ids: String!
    ) {
        DeleteLegends(
            legend_ids: $legend_ids
        ) {
            id
        }
    }
`

// TEXTS

const textProperties = `
    id
    title
    body
    button_text
    realm
`

export const GET_TEXTS = gql`
    query getTexts {
        texts {
            ${textProperties}
        }
    }
`

export const GET_TEXT_BY_ID = gql`
    query getTextByID($id: ID!) {
        text(id: $id) {
            ${textProperties}
        }
    }
`

export const CREATE_TEXT = gql`
    mutation createText(
        $title: String!,
        $body: String!,
        $button_text: String,
        $realm: String!
    ) {
        CreateText(
            title: $title,
            body: $body,
            button_text: $button_text,
            realm: $realm,
        ) {
            ${textProperties}
        }
    }
`

export const UPDATE_TEXT = gql`
  mutation updateText(
      $id: ID!,
      $title: String!,
      $body: String!,
      $button_text: String,
      $realm: String!
    ) {
        UpdateText(
            id: $id,
            title: $title,
            body: $body,
            button_text: $button_text,
            realm: $realm,
        ) {
            ${textProperties}
        }
    }
`

// Admin

export const LOGIN = gql`
    query loginCall($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            token
        }
    }
`

export const WHO_AM_I = gql`
    mutation WhoAmI {
        WhoAmI {
            name, email
        }
    }
`
