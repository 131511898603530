<template>
  <div class="o-wrapper u-margin-bottom u-margin-bottom-large@tablet">

    <div class="u-margin-bottom">
      <router-link :to="{ name: 'Home' }">
        <IconArrow class="u-margin-right-tiny" /> Overzicht
      </router-link>

      <h1>Karels Crypto - Archief</h1>
    </div>

    <div v-if="items && items.length">
      <PuzzleList :items="items"/>

      <button v-if="total > items.length"
        class="c-button c-button--ghost"
        @click="handleClickLoadMore"
        :disabled="isLoading"
      >
        Laad meer
      </button>
    </div>

  </div>
</template>

<script>
  import {
    GET_PUZZLES
  } from '@/utils/graphql'

  import { uniqBy } from 'lodash'

  import Puzzle from '@/components/puzzle/Puzzle'
  import PuzzleList from '@/components/puzzle/PuzzleList'
  import IconArrow from '@/components/icons/IconArrow'

  export default {
    name: 'PuzzleArchive',

    data () {
        return {
          currentPage: 1,
          limit: 10,
          total: 0,
          items: [],
          isLoading: true
        }
    },

    methods: {
      handleClickLoadMore () {
        this.isLoading = true
        this.currentPage++
      }
    },

    // Apollo-specific options
    apollo: {
      puzzles: {
        query () {
          return GET_PUZZLES
        },
        variables () {
          let variables = {
            published: true,
            page: this.currentPage,
            limit: this.limit
          }

          return variables
        },
        deep: false,
        result (result, key) {
          this.isLoading = result.loading
          const output = result.data[key]
          this.total = output.total
          this.items = uniqBy([...this.items, ...output.data], 'id')
        }
      }
    },

    components: {
      Puzzle,
      PuzzleList,
      IconArrow
    }
  }
</script>
