<template>
    <div>
        <h2>Puzzles</h2>

        <table class="c-table" v-if="items.length">
            <tr>
                <th class="c-table__column c-table__column--head">
                    Puzzel
                </th>
                <th class="c-table__column c-table__column--head">
                    Publicatiedatum
                </th>
                <th class="c-table__column c-table__column--head">
                    Actief?
                </th>
                <th class="c-table__column c-table__column--head">

                </th>
                <th class="c-table__column c-table__column--head">

                </th>
            </tr>
            <router-link v-for="item in items"
                 :to="{ name: 'AdminPuzzleFormEdit', params: { id: item.id } }"
                 :key="item.id"
                 tag="tr"
                 class="c-table__row c-table__row--link"
            >
                <td class="c-table__column">
                    {{ item.title }}
                </td>

                <td class="c-table__column">
                    {{ item.start_date|date }}

                    <span v-if="homePuzzle && homePuzzle.id === item.id" class="c-chip u-margin-left-small">
                        ✓ Nu live
                    </span>
                </td>

                <td class="c-table__column">
                    <span v-if="item.published" class="u-color-success">
                        ✓
                    </span>
                    <span v-else class="u-color-error">
                        ✗
                    </span>
                </td>

                <router-link :to="{ name: 'PuzzleDetail', params: { id: item.id, preview: true } }" tag="td" class="c-table__column">
                    Preview <IconArrow direction="right" class="u-margin-left-tiny" />
                </router-link>

                <td class="c-table__column u-align-right">
                    ✎ Bewerk
                </td>
            </router-link>
        </table>

        <div class="o-flex o-flex--justify-space-between">
            <router-link :to="{ name: 'AdminPuzzleFormCreate' }" class="c-button c-button--ghost u-margin-bottom-tiny">
                + Nieuwe puzzel
            </router-link>

            <button v-if="total > items.length"
                    class="c-button c-button--primary u-margin-bottom-tiny"
                    @click="handleClickLoadMore"
                    :disabled="isLoading"
            >
                Laad meer
            </button>
        </div>
    </div>
</template>

<script>
  import {
    GET_PUZZLES,
    GET_HOME_PUZZLE
  } from '@/utils/graphql'

  import { uniqBy } from 'lodash'

  import IconArrow from '@/components/icons/IconArrow'

  export default {
    name: 'AdminPuzzleList',

    data () {
      return {
        currentPage: 1,
        limit: 10,
        total: 0,
        items: [],
        isLoading: true
      }
    },

    methods: {
      handleClickLoadMore () {
        this.isLoading = true
        this.currentPage++
      }
    },

    apollo: {
      puzzles: {
        query () {
          return GET_PUZZLES
        },
        variables () {
          let variables = {
            page: this.currentPage,
            limit: this.limit
          }

          return variables
        },
        deep: false,
        fetchPolicy: 'no-cache',
        result (result, key) {
          this.isLoading = result.loading
          const output = result.data[key]
          this.total = output.total
          if (this.currentPage === 1) {
            this.items = output.data
            return
          }

          this.items = uniqBy([...this.items, ...output.data], 'id')
        }
      },
      homePuzzle: {
        query () {
          return GET_HOME_PUZZLE
        },
        // Variables: deep object watch
        deep: false,
        fetchPolicy: 'no-cache',
        // We use a custom update callback because
        // the field names don't match
        update: data => data.published_puzzle
      }
    },

    components: {
      IconArrow
    }
  }
</script>
