<template>

  <div class="c-form__group" :class="{ 'is-invalid': formError && error }">

    <label
            v-if="label"
            :for="inputId"
            class="c-form__label">
      {{ label }}
    </label>

    <div class='c-form__input-wrapper c-wysiwyg'>
<!--        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">-->
        <editor-menu-bar :editor="editor">
            <div class="c-wysiwyg__menu" slot-scope="{ commands, isActive }">

              <button
                      type="button"
                      class="c-wysiwyg__menu__item"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
              >
                Bold
              </button>

              <button
                      type="button"
                      class="c-wysiwyg__menu__item"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
              >
                Italic
              </button>

              <button
                      type="button"
                      class="c-wysiwyg__menu__item"
                      :class="{ 'is-active': isActive.paragraph() }"
                      @click="commands.paragraph"
              >
                P
              </button>

              <button
                      type="button"
                      class="c-wysiwyg__menu__item"
                      :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                      @click="commands.heading({ level: 1 })"
              >
                H1
              </button>

              <button
                      type="button"
                      class="c-wysiwyg__menu__item"
                      :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                      @click="commands.heading({ level: 2 })"
              >
                H2
              </button>

              <button
                      class="c-wysiwyg__menu__item"
                      type="button"
                      :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                      @click="commands.heading({ level: 3 })"
              >
                H3
              </button>

              <button
                      type="button"
                      class="c-wysiwyg__menu__item"
                      :class="{ 'is-active': isActive.bullet_list() }"
                      @click="commands.bullet_list"
              >
                Lijst
              </button>

              <button
                      type="button"
                      class="c-wysiwyg__menu__item"
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list"
              >
                Genummerde lijst
              </button>

              <button
                      type="button"
                      class="c-wysiwyg__menu__item"
                      @click="commands.horizontal_rule"
              >
                __
              </button>

              <button
                      type="button"
                      class="c-wysiwyg__menu__item"
                      @click="commands.undo"
              >
                  ⟲
              </button>

              <button
                      type="button"
                      class="c-wysiwyg__menu__item"
                      @click="commands.redo"
              >
                  ⟳
              </button>

            </div>
        </editor-menu-bar>

        <EditorContent
            :editor="editor"
            class="c-form__input c-wysiwyg__content"
            :class="{
              'c-form__input': true,
              'c-form__input--error': formError && error
            }"
            :id="inputId"
            :name="name"
            :placeholder="placeholder"
            :required="required === true ? true : false"
        />

    </div>

    <p v-if="helper" class="u-margin-top-tiny u-margin-bottom-none">
      <component :is="$options.filters.dynamic(`<div>${helper}</div>`) && {template: $options.filters.dynamic(`<div>${helper}</div>`)}" />
    </p>

    <p v-if="formError && error" class='c-form__error'>{{ error }}</p>

  </div>

</template>

<script>
// import { mapState } from 'vuex'

import {
  Editor,
  EditorContent,
  EditorMenuBar
} from 'tiptap'

// Extensions:
import {
  Heading,
  HardBreak,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  History,
  HorizontalRule
  // Blockquote,
  // CodeBlock,
  // TodoItem,
  // TodoList,
  // Code,
  // Link,
  // Strike,
  // Underline,
} from 'tiptap-extensions'

export default {
  name: 'FieldWysiwyg',

  props: [
    'module',
    'subform',
    'isSubfield', // used for animation staggering
    'inputId',

    'label',
    'placeholder',

    'name',
    'value',
    'error',
    'required',

    'tooltip',
    'helper',
    'formError'
  ],

  data () {
    return {
      editor: null
    }
  },

  mounted () {
    this.textValue = this.value
    this.initEditor()
  },

  beforeDestroy () {
    this.editor.destroy()
  },

  methods: {
    initEditor () {
      this.editor = new Editor({
        content: this.value,
        extensions: [
          // HardBreak,
          // OrderedList,
          // BulletList,
          // ListItem,
          // Bold,
          // Italic

          new Heading({ levels: [1, 2, 3] }),
          new HardBreak(),
          new OrderedList(),
          new BulletList(),
          new ListItem(),
          new Bold(),
          new Italic(),
          new History(),
          new HorizontalRule()
        ],
        onUpdate: ({ getHTML }) => {
          const value = getHTML()
          /*
          *   Set Value
          */
          this.$store.commit('setFieldValue', {
            module: this.module,
            subform: this.subform,
            field: this.name,
            value
          })

          /*
          *   Validate Field
          */
          this.$store.dispatch('forms/validateField', {
            module: this.module,
            subform: this.subform,
            field: this.name,
            value
          })
        }
      })
    }
  },

  components: {
    Editor,
    EditorContent,
    EditorMenuBar
  }
}
</script>
