<template>
    <div class="c-solution o-inline-flex o-flex--column u-margin-bottom" v-if="puzzle">
        <h2>Oplossing</h2>

        <PuzzleSolutionGrid class="u-margin-bottom"/>

        <div v-if="!showSolution" class="o-flex o-flex--column o-flex--align-center u-align-center">
            <button class="c-button c-button--primary" @click="handleClickValidateSolution">
                Controleer mijn oplossing
            </button>

            <div v-if="!puzzleSolutionIsAvailable && puzzleSolutionDateAvailable" class="u-margin-top-medium-small">
                De oplossing wordt zichtbaar vanaf {{ puzzleSolutionDateAvailable|date('D MMMM') }}
            </div>

            <button v-else-if="puzzleSolutionIsAvailable" class="c-button c-button--ghost u-margin-top-small" @click="togglePopup(popupTypeShowSolution)">
                Toon oplossing
            </button>
        </div>

        <Popup v-if="showPopup && popupType === popupTypeValidateSolution" size="large">
            <div class="o-flex o-flex--column o-flex--align-center">
                <h2 class="o-flex--align-self-start">Oplossing</h2>

                <PuzzleSolutionGrid class="u-margin-bottom o-flex--align-self-start"/>

                <div v-if="showValidation && validationResult">
                    <div v-if="!validationResult.correctSolution" v-html="textSolutionIncorrect"></div>

                    <template v-else-if="!validationResult.correctAnswers">
                        De oplossing is gevonden maar niet alle horizontale woorden zijn correct ingevuld. Kijk nog eens goed na.
                    </template>

                    <template v-else>
                        <p>
                            Fantatisch! U hebt de crypto gekraakt.<br>
                            Laat het weten aan uw vrienden.
                        </p>

                        <div class="o-flex o-flex--column o-flex--align-center u-align-center">
                            <a :href="`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`" class="c-button c-button--primary u-margin-bottom-small" target="_blank">
                                Deel op Facebook
                            </a>

                            <a :href="`https://twitter.com/share?url=${shareUrl}&text=${shareText}`" class="c-button c-button--ghost" target="_blank">
                                Deel op Twitter
                            </a>
                        </div>

                    </template>
                </div>

                <button v-else class="c-button c-button--primary u-margin-bottom-small" @click="validateSolution">
                    Bevestig oplossing
                </button>
            </div>
        </Popup>

        <Popup v-if="showPopup && popupType === popupTypeShowSolution">
            <div class="o-flex o-flex--column o-flex--align-center">
                <p>
                    Bent u zeker dat u de oplossing wil bekijken?
                </p>

                <button class="c-button c-button--primary u-margin-bottom-small" @click="setShowSolution">
                    Bekijk oplossing
                </button>

                <button class="c-button c-button--ghost u-margin-bottom-small" @click="togglePopup(popupTypeShowSolution)">
                    Annuleren
                </button>
            </div>
        </Popup>
    </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { find } from 'lodash'

  import PuzzleSolutionGrid from '@/components/puzzle/PuzzleSolutionGrid'
  import Popup from '@/components/Popup'

  export default {
    name: 'PuzzleSolution',

    data () {
      return {
        showValidation: false,
        validationResult: null,
        popupTypeValidateSolution: 'validate_solution',
        popupTypeShowSolution: 'show_solution',
        // shareUrl: window.location.href,
        shareUrl: 'https://www.standaard.be/crypto',
        shareText: 'Ik heb Karels Crypto gekraakt! Lukt het u ook?'
      }
    },

    methods: {
      handleClickValidateSolution () {
        this.showValidation = null
        this.toggleValidateSolutionPopup()
      },

      toggleValidateSolutionPopup () {
        this.togglePopup(this.popupTypeValidateSolution)
      },

      togglePopup (type) {
        this.$store.commit('togglePopup', { type })
      },

      validateSolution () {
        this.$store.dispatch('validateSolution')
          .then((result) => {
            this.validationResult = result
            this.showValidation = true
            // if (!result.correctSolution) {
            //   this.validationMessage = 'Oplossing incorrect. Kijk alles nog een goed na.'
            //   return
            // }
            //
            // if (!result.correctAnswers) {
            //   this.validationMessage = 'De oplossing is gevonden, maar niet alle horizontale woorden zijn (correct) aangevuld.'
            //   return
            // }
            //
            // this.validationMessage = 'Oplossing correct. Proficiat!'
        })
      },

      setShowSolution () {
        if (this.puzzleSolutionIsAvailable) {
            this.$store.commit('setShowSolution', { show: true })
        }

        this.$store.commit('resetPopup')
      }
    },

    computed: {
      ...mapState({
        puzzle: state => state.currentPuzzle,
        showSolution: state => state.showSolution,
        texts: state => state.texts,
        showPopup: state => state.showPopup,
        popupType: state => state.popupType
      }),

      puzzleSolutionDateAvailable () {
        return this.$store.getters.puzzleSolutionDateAvailable({ puzzle: this.puzzle })
      },

      puzzleSolutionIsAvailable () {
        return this.$store.getters.puzzleSolutionIsAvailable({ puzzle: this.puzzle })
      },

      textSolutionIncorrect () {
        const text = find(this.texts, item => item.realm === 'solution_incorrect')
        return text ? text.body : 'Oplossing is niet correct. Kijk alles nog eens goed na.'
      }
    },

    components: {
      PuzzleSolutionGrid,
      Popup
    }
  }
</script>
