<template>
    <g class="c-grid__cell">
        <rect class="c-grid__cell__rect"
          :x="x"
          :y="y"
          :width="size"
          :height="size"
        ></rect>

        <text v-if="text"
              :x="textX ? textX : x + size/2"
              :y="y + (size * 2/3)"
              class="c-grid__cell__text"
              text-anchor="middle"
        >
            {{ text }}
        </text>

        <text v-if="placeholderText"
              :x="placeholderTextIsSmall ? x + (size * 9/10) : x + size/2"
              :y="placeholderTextIsSmall ?  y + (size * 9/10) : y + (size * 6/10)"
              class="c-grid__cell__placeholder"
              :class="{ 'c-grid__cell__placeholder--small': placeholderTextIsSmall }"
              :text-anchor="placeholderTextIsSmall ? 'end' : 'middle'"
        >
            {{ placeholderText }}
        </text>
    </g>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'PuzzleCell',

    props: ['text', 'placeholderText', 'placeholderTextIsSmall', 'xIndex', 'yIndex', 'textX'],

    computed: {
      ...mapState({
        gridSettings: state => state.gridSettings,
        size: state => state.gridSettings.cellSize
      }),

      x () {
        return this.xIndex * this.size
      },

      y () {
        return this.yIndex * this.size
      }
    }
  }
</script>
