<template>
    <PuzzleCell
        :class="{
            'c-grid__cell--input': true,
            'c-grid__cell--solution': isSolutionCell,
            'c-grid__cell--highlighted': isHighlighted,
            'c-grid__cell--connected': hasActiveNumberConnector,
            'c-grid__cell--active': isActive
        }"
        :xIndex="xIndex"
        :yIndex="yIndex"
        :text="text ? text.toUpperCase() : ''"
        :placeholderText="placeholderText"
        :placeholderTextIsSmall="isActive || !!text"
        @click.native="handleClick"
    />
</template>

<script>
  import { mapState } from 'vuex'
  import { find } from 'lodash'
  import { Fragment } from 'vue-fragment' /* Special element ignored by DOM */

  import PuzzleCell from '@/components/puzzle/PuzzleCell'

  // @TODO: refactor connected inputs
  export default {
    name: 'PuzzleCellInput',

    props: ['row', 'inputIndex', 'yIndex', 'isEmpty'],

    methods: {
      handleClick () {
        this.$store.commit('setActiveRowIndex', { index: this.yIndex })
        this.$store.commit('setActiveInputIndex', { index: this.inputIndex })
        // this.$store.commit('setActiveNumberConnector', { numberConnector: this.numberConnector })
        this.$store.commit('setInputDirection', { inputDirection: this.isSolutionCell && this.inputIndex !== 0 ? 'vertical' : 'horizontal' })
        document.querySelector('.js-grid-input').focus()
      }
    },

    computed: {
      ...mapState({
        puzzle: state => state.currentPuzzle,
        showSolution: state => state.showSolution,
        answers: state => state.answers,
        numberConnectors: state => state.currentPuzzle.legends,
        gridSettings: state => state.gridSettings,
        activeNumberConnector: state => state.gridSettings.activeNumberConnector,
        size: state => state.gridSettings.cellSize
      }),

      xIndex () {
        return this.inputIndex + this.row.offset + 1
      },

      numberConnector () {
        const hasConnectorIndex = find(this.row.numbers, (item) => item.index === this.inputIndex)

        if (!hasConnectorIndex) {
          return null
        }

        const answerLetter = this.row.answer[this.inputIndex]
        return find(this.numberConnectors, (item) => {
          return item.letter.toLowerCase() === answerLetter.toLowerCase()
        })
      },

      hasActiveNumberConnector () {
        if (this.isActive || !this.gridSettings.inputIsFocused) {
          return false
        }

        const numberConnector = this.numberConnector

        if (!numberConnector) {
          return false
        }

        const activeNumberConnector = this.activeNumberConnector

        if (!activeNumberConnector) {
          return false
        }

        return numberConnector.id === activeNumberConnector.id
      },

      text () {
        return this.showSolution ? this.solutionValue : this.inputValue
      },

      solutionValue () {
        return this.puzzle.rows[this.yIndex].answer[this.inputIndex]
      },

      inputValue () {
        return this.answers[this.yIndex][this.inputIndex]
      },

      placeholderText () {
        const numberConnector = this.numberConnector
        return numberConnector ? numberConnector.number : null
      },

      isSolutionCell () {
        const center = Math.round(this.gridSettings.cellsPerRow / 2) - 1
        const offset = this.row.offset
        const currentCharacterIndex = offset + this.inputIndex

        return currentCharacterIndex === center
      },

      isHighlighted () {
        return this.gridSettings.inputIsFocused && this.gridSettings.activeRowIndex === this.yIndex
      },

      isActive () {
        return this.isHighlighted && this.gridSettings.activeInputIndex === this.inputIndex
      }
    },

    components: {
      Fragment,
      PuzzleCell
    }
  }
</script>
