<template>
  <figure class="c-icon c-icon--arrow" :style="style">

    <svg>
      <use xlink:href="#arrow-left"></use>
    </svg>

  </figure>
</template>

<script>

export default {
  name: 'IconArrow',

  props: ['direction'],

  computed: {
    style () {
      const direction = this.direction
      const style = {}

      if (!direction) {
        return style
      }

      if (direction === 'up') {
        style.transform = 'rotate(90deg)'
      }

      if (direction === 'right') {
        style.transform = 'rotate(180deg)'
      }

      if (direction === 'down') {
        style.transform = 'rotate(270deg)'
      }

      return style
    }
  }
}
</script>
