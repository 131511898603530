<template>
  <div class="o-wrapper u-margin-bottom u-margin-bottom-large@tablet">
    <h1>We konden deze inhoud niet vinden</h1>

    <p>
      <router-link :to="{ name:'Home' }" class="c-button c-button--ghost">
        Terug naar overzicht
      </router-link>
    </p>
  </div>
</template>
<script>
export default {
  name: 'NotFound'
}
</script>
