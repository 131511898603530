<template>
    <div class="c-form__group">

        <div class="c-form__checkbox">
            <input
                    :id="`field-${fieldId}`"
                    class="c-form__checkbox__input"
                    type="checkbox"
                    :name="name"
                    :checked="value === true || value === 'true' ? true : false"
                    v-on:change="handleChange"
            />

            <label
                    v-if="label"
                    :for="`field-${fieldId}`"
                    :class="{
                  'c-form__checkbox__label': true,
                  'u-error': formError && error
            }">

                <div class="c-form__checkbox__checkmark">✓</div>

                <span v-if="rawHtml">
                  <component :is="$options.filters.dynamic(label) && {template: $options.filters.dynamic(label)}"/>
                </span>

                <span v-else>{{ label }}</span>
            </label>
        </div>

        <p v-if="formError && error" class='c-form__error'>{{ error }}</p>
    </div>
</template>

<script>
  /*
   * Docs: <component :is="label && {template: label}" />
   * --
   * This will render the content as a new component. Why do we do this? Because we want to use router-links ;).
   */
  export default {
    name: 'FieldCheckbox',

    props: [
      'module',
      'subform',
      'fieldId',

      'label',

      'name',
      'value',
      'error',
      'required',

      'tooltip',
      'formError',
      'rawHtml'
    ],

    methods: {
      handleChange (e) {
        const checkboxValue = !this.value

        /*
        *   Set Value
        */
        this.$store.commit('setFieldValue', {
          module: this.module,
          subform: this.subform,
          field: e.target.name,
          value: checkboxValue
        })

        /*
        *   Validate Field
        */
        this.$store.dispatch('forms/validateField', {
          module: this.module,
          subform: this.subform,
          field: e.target.name,
          value: checkboxValue
        })

        this.$emit('change', checkboxValue)
      }
    }
  }
</script>
