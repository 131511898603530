import Vue from 'vue'
import Vuex from 'vuex'

import { initialState } from './state'
import mutations from './mutations'
import actions from './actions'
import forms from './forms'
import getters from './getters'

import createLogger from '../utils/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
    state: initialState(),
    mutations,
    actions,
    getters,
    modules: {
        forms: forms
    },
    middlewares: debug ? [createLogger()] : [],
    strict: debug
})

export default store
