import actions from './actions'
import getters from './getters'

// Words overwritten by translation file
const state = {
    validationMessages: {
        global: 'Niet alle velden zijn juist ingevuld.',
        globalStep: 'Niet alle velden zijn juist ingevuld. Kijk dit even na voor u naar de volgende stap gaat.',
        required: 'Dit veld is verplicht.',
        digits: 'Dit veld is verplicht.',
        email: 'Dit is geen correct e-mailadres.'
    }
}

export default {
  namespaced: true,
  state,
  actions,
  getters
}
